import React, {useState, useEffect} from 'react'
import Navlist from '../dashboard/Navlist';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { BASE } from "../../actions";
import { hasRole } from "../../utils/hasRoleCheck";
import ProfileMenu from "../common/ProfileMenu";
import DropdownList from 'react-widgets/lib/DropdownList';
import CaseReportList from '../case-report/CaseReportList'
import { MDBDataTableV5 } from 'mdbreact';
import AppBarMain from "../shared/AppBarMain";
import DrawerMenu from "../shared/DrawerMenu";
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}{new Date().getFullYear()}
        {'.'}
        <Link color="inherit" href="https://fountainitservice.com">
          Fountain Software & IT Services
        </Link>{' '}
        
      </Typography>
    );
  }
  
  const drawerWidth = 240;
  
  const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  }));
 
export default function CaseReport() {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const [age,setAge] = React.useState('');
    const [case_type,setCaseType] = React.useState('');
    const [case_for,setCaseFor] = React.useState('');
    const [error, setError] = useState(null);
    const [emailerror, setEmailError] = useState(null);
    const [passworderror, setPasswordError] = useState(null);
    const [repassworderror, setRePasswordError] = useState(null);
  
    const [load, setLoad] = useState(null);
    const uploadFile = (event)=> {
        event.preventDefault();
        const data = new FormData(event.target);
       
        const file = event.target.bulkFile;
        const api = axios.create({
            baseURL: BASE
          });
          api.post("/case/bulk/upload", data)
            .then((res) => {
              setLoad(false);
             
            })
            .catch(res => {
              setLoad(false); 
              
              setError(res.response.data.name);
              setEmailError(res.response.data.email);
              setPasswordError(res.response.data.password);
              setRePasswordError(res.response.data.confirm_password);
              
          })
       /* axios.post('case/bulk/upload', {'bulkFile':data}, {
            headers: {
              'Content-Type': file.type
            }
        })*/
    }
    const handleCase = (e) => {
      setLoad(true);
      e.preventDefault();
      const { history } = e;
     
      const data = new FormData(e.target);
    
      const api = axios.create({
        baseURL: BASE
      });
      api.post("/case/bulk/upload", data)
        .then((res) => {
          setLoad(false);
         
        })
        .catch(res => {
          setLoad(false); 
          
          setError(res.response.data.name);
          setEmailError(res.response.data.email);
          setPasswordError(res.response.data.password);
          setRePasswordError(res.response.data.confirm_password);
          
      })
    };
  const handleChange = (event) => {
   
    setCaseType(event.target.value);
    setCaseFor(event.target.value);
  };
    const handleDrawerClose = () => {
      setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [post, setPost] = useState([]);
    const [types, setType] = useState([]);
    const [casefors, setCase] = useState([]);
    const [courtno, setCourtno] = useState([]);
    const [areas, setAreas] = useState([]);
 // const id = props.match.params.id;
 
  const api = axios.create({
    baseURL: BASE
  });
  
  
  React.useEffect(() => {
    api.get('/case-assign-report')
    .then(res => {
      const cases = res.data;
      // ree = cases;
      setPost( cases );
        setDatatable({
            columns: [
                {
                  label: 'CASE ID',
                  field: 'id',
                  width: 150,
                  attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                  },
                },
                {
                    label: 'Lawyer',
                    field: 'name',
                    width: 200,
                  },
                {
                  label: 'Case ID NO',
                  field: 'case_id_no',
                  width: 270,
                },
                {
                  label: 'Case NO',
                  field: 'case_no',
                  width: 200,
                },
                {
                  label: 'Next Date',
                  field: 'next_date',
                  width: 200,
                },
                
              ],
              
              rows:cases,
        }
        );
    })
    
    
  }, []);
  
  const handleDownload = (event) => {
    const api = axios.create({
        baseURL: BASE
      });
    api.get('/courtno')
    .then(res => {
        const url =BASE + "/case/bulk/download";
        window.open(url,"_blank");
     
    }).catch((error) => {
      //console.log('image not upload, catch, error.....', error.response);
    });
  };
  const fetch=()=>{
    const api = axios.create({
      baseURL: BASE
    });
    
    api.get('/cases')
      .then(res => {
        const cases = res.data;
        this.setState({ cases });
      })
    }

const listItems = post;
   const [datatable, setDatatable] = React.useState({});
      return (
          <div className={classes.root}>
          <CssBaseline />
              <AppBarMain pageName="Case Assign Report" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
              <DrawerMenu />
          <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <div style={{ height: 400, width: '100%' }}>
              <h4 style={{ background:'#ddd',padding:'10px'}}> Case Asign Report</h4>
              
              <CaseReportList></CaseReportList>
           </div>
           </Container>
          
           </main>
          
          </div>
      )
}
