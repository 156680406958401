import React from 'react'

export default function getUserData() {
    //const userDataString = localStorage.getItem('userData');
     /*eslint-disable*/
     const userDataString = localStorage.getItem('userData');
     /*eslint-enable*/
     
     const userData = JSON.parse(userDataString);
     
     //name, email, status, token, user_id
     if (userData != null) {
         userData.token = 'Token Value Changed in this Variable'; 
     }
 
     return userData; 
}
