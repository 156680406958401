import firebase from 'firebase/app';
import 'firebase/messaging';

var firebaseConfig = {
  apiKey: "AIzaSyCYrj2YXjY22fdJ_LhVOgkAPR9qzsFKWAI",
    authDomain: "fcmtest-bbac3.firebaseapp.com",
    databaseURL: "https://fcmtest-bbac3.firebaseio.com",
    projectId: "fcmtest-bbac3",
    storageBucket: "fcmtest-bbac3.appspot.com",
    messagingSenderId: "925619220423",
    appId: "1:925619220423:web:424998a9cdd532c299f3e2"
};

firebase.initializeApp(firebaseConfig);
export const messaging = firebase.messaging();

export const getToken = () => {
  return messaging.getToken({vapidKey: 'BBBpxYDY94wyMM2edZIAr1qCiK_11ty4PYWzzXE7OkQioBV65RsuI5A7Hnw7VrGcwY74JUMH7EnRB2WrTyDneMI'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      localStorage.setItem('fcm_token',currentToken);
      //setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      //setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       console.log('xxxxx')
//       resolve(payload);
//     });
    
// });
