import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import DatePicker from "react-datepicker";
import { hasRole } from '../../utils/hasRoleCheck';
import Select from 'react-select';

import moment from "moment";
import {
    ROLE_ADMIN,
    ROLE_LAWYER,

} from '../../components/common/ConstantValues';
import { Widgets } from '@material-ui/icons';
export default class SubscriptionExpiryList extends Component {
    state = {
        cases: [],
        checked: '',
        aquaticCreatures: [],
        caseIdNos: [],
        selectedLawyer:null,
        selectedIDNO:null,
        selectedStartDate: null,
        startDate: null,
        status:null,
    }

    onChange(e) {
        let value = e.target.value;
        let id = e.target.id;
        const api = axios.create({
            baseURL: BASE
        });

        api.put('/setnotifstatus/'+id+'/'+value)
            .then(res => {
                this.fetch();
                alert('sucessfully Updated!!!');
            })

    }
    handleChange = e =>{
        const api = axios.create({
            baseURL: BASE
        });
        if(e){
            this.setState({ selectedLawyer: e.id });
            api.get('/all-cases-user-id-no/'+e.id)
                .then(res => {
                    const caseIdNos = res.data;
                    this.setState( {caseIdNos} );

                })
        }

    }
    handleChangeCase = e =>{
        if(e){
            this.setState({ selectedIDNO: e.id });
        }
    }
    fetch(){
        const api = axios.create({
            baseURL: BASE
        });
        if(hasRole([ROLE_ADMIN])){
            api.get('/subscription-expiry-report')
                .then(res => {
                    const cases = res.data;
                     this.setState({ cases });
                })
            api.get('/all-lawyers')
                .then(res => {
                    const aquaticCreatures = res.data;

                    this.setState( {aquaticCreatures} );

                })
            api.get('/all-cases-id-no')
                .then(res => {
                    const caseIdNos = res.data;
                    this.setState( {caseIdNos} );

                })


        }else{
            const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
            api.get('/cases/'+user_id+'/0')
                .then(res => {
                    const cases = res.data;
                    this.setState({ cases });
                })
        }
    }
    componentDidMount() {
        this.fetch();

    }
    downloadSample(){
        const parentId = this.state.parentID.id;
        const request = this.props.getSampleBuildingFile(parentId);

        request.then((response) => {

            if (response.payload.data != null) {
                const url =BASE + "/"+ response.payload.data;
                window.open(url,"_blank");
            } else {

            }
        }).catch((error) => {

        });
    }
    handleSubmit = event => {
        event.preventDefault();
        const api = axios.create({
            baseURL: BASE
        });
        const data = new FormData(event.target);
        const user_id = data.get('lawyer_id');
        const status = data.get('status');
        this.setState({status:status})
        const date = this.state.startDate;
        api.get('/subscription-expiry-report',{params: {
                status:status,
                date: date
            }})

            .then(res => {
                const cases = res.data;
                this.setState({ cases });
            })
    }
    setStartDate(date) {
        this.setState({
            startDate:moment(date).format("YYYY-MM-DD"),
            params: {
                ...this.state.params,
                startDate: moment(date).format("YYYY-MM-DD")
            },
            selectedStartDate: date,
            url: ''
        }, () => {
            console.log("start date selected")
            console.log(this.state.params)
        });
    }
     handleDownload = (event) => {
        const status = this.state.status;
         const url =BASE + "/subscription-expiry-report-download?status="+status;
         window.open(url,"_blank");

    };
    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        {/*<div className="form-group col-md-4 date-selector">
                            <label className="label">Date</label><br/>
                            <DatePicker className="search-field form-control"
                                        dateFormat="dd MMM yyyy"
                                        placeholderText="Select..."
                                        selected={this.state.selectedStartDate}
                                        onChange={(date) => this.setStartDate(date)}
                            />
                        </div>*/}
                        <div className="form-group col-md-4">
                            <label>Select</label>
                            {this.aquaticCreatures}
                            <select className='form-control' name='status' id="status">
                                <option value="all">All</option>
                                <option value="expired">Expired</option>
                                <option value="active">Active</option>
                            </select>
                        </div>

                        <label></label>
                        <div className="form-group col-md-1 pt-4"><button type="submit" className="btn btn-primary btn-sm">GO</button></div>
                    </div>

                </form>
                <a href='#/subscription-expiry-report' onClick={this.handleDownload} className="btn btn-info btn-sm">Export</a>
                <table className="table table-striped table-responsive">
                    <thead>
                    <tr>
                        <th>SL No.</th>
                        <th>Lawyer ID</th>
                        <th>Lawyer Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Current Package</th>
                        <th>Sub. Expiry Date</th>
                        <th>Last Login Date</th>
                        <th>Status</th>

                    </tr>

                    </thead>
                    <tbody>
                    { this.state.cases.map((cases, index) =><tr>
                        <td>{++index}</td>
                        <td>{cases.lawyer_id}</td>
                        <td>{cases.lawyer_name}</td>
                        <td>{cases.phone}</td>
                        <td>{cases.lawyer_email}</td>
                        <td>{cases.package_name}</td>

                        <td>{cases.expired_on}</td>
                        <td>{cases.last_login}</td>
                        <td>{cases.status}</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        )
    }
}
