import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import {LOCAL_STORAGE_DATA_KEYNAME} from "../../actions/types"
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { hasRole } from '../../utils/hasRoleCheck';
import {
  ROLE_ADMIN,
  ROLE_LAWYER,

} from '../common/ConstantValues';

//import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";



export default class OpenIssueList extends Component {

    state = {
        issues: [],
        checked: '',
      }
      
      onChange(e) {   
        let value = e.target.value;
        let id = e.target.id;
        
        const api = axios.create({
          baseURL: BASE
        });
        
      api.put('/setnotifstatus/'+id+'/'+value)
        .then(res => {
          this.fetch();
          alert('sucessfully Updated!!!');
        })
        
    }
    handleSearch(e){
      let value = e.target.value;
      let id = e.target.name;
      const api = axios.create({
        baseURL: BASE
      });
      if(hasRole([ROLE_ADMIN])){
      api.get('/case-all-active-list?search='+value)
        .then(res => {
          const cases = res.data;
          this.setState({ cases });
        })
      }else{
        const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
        api.get('/cases/'+user_id+'/0')
        .then(res => {
          const cases = res.data;
          this.setState({ cases });
        })
      }
    }
    onChangeClose(e) {   
      let value = e.target.value;
      let id = e.target.id;
      
      const api = axios.create({
        baseURL: BASE
      });
      
    api.put('/setclosestatus/'+id+'/'+value)
      .then(res => {
        this.fetch();
        alert('sucessfully Updated!!!');
      })
      
  }
    
      fetch(){
        const api = axios.create({
          baseURL: BASE
        });
       
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          api.get('/support-tickets/user/'+user_id)
          .then(res => {
            const issues = res.data;
            this.setState({ issues });
          })
        
      }
      componentDidMount() {
        this.fetch();
      } 
      // downloadSample(){
      //   const parentId = this.state.parentID.id;
      //   const request = this.props.getSampleBuildingFile(parentId);
      //   request.then((response) => {
          
      //     if (response.payload.data != null) {
      //       const url =BASE + "/"+ response.payload.data;
      //       window.open(url,"_blank");
      //     } else {
           
      //     }
      //   }).catch((error) => {
          
      //   });
      // }
      closeIssue=(ticket_id)=>{
        //e.preventDefault()
       // alert(ticket_id)
        const api = axios.create({
          baseURL: BASE
        });
       
          //const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          api.post('/support-tickets/closeIssue',{ticket_id:ticket_id})
          .then(res => {
            const issues = res.data;
         this.fetch();
          })
      }
    render() {
        return (
            <div>
             
             
               

               <div >
      <div>
        <div small className="mb-4">
          
          <div className="border-bottom ">
          <div  className="row py-2">
                 <label className="col-md-1 py-2">Search</label>
                 <div className="col-md-6">
               <input type="text" className="form-control" placeholder="Search" onChange={this.handleSearch.bind(this)} name="search"></input>
               </div>
               <div className="col-md-5">
               <a href="#/add-issue" className="btn btn-primary float-right btn-sm">Add New Issue</a>
          </div>
               </div>
           
           
          </div>
          <div className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                 
                  <th scope="col" className="border-0">
                  Issue date
                  </th>
                  <th scope="col" className="border-0">
                  Issue no
                  </th>
                  {(hasRole([ROLE_ADMIN])) &&
                  <th scope="col" className="border-0">
                  Lawyer Id
                  </th>
    }
     {(hasRole([ROLE_ADMIN])) &&
                  <th scope="col" className="border-0">
                  Lawyer Name
                  </th>
                   }
                  <th scope="col" className="border-0">
                  Subject
                  </th>
                  <th scope="col" className="border-0">
                  Description
                  </th>
                  <th scope="col" className="border-0">
                  Status
                  </th>
                  <th scope="col" className="border-0">
                  Action
                  </th>
                </tr>
              </thead>
              <tbody>
                    { this.state.issues.map((issues, index) =>
                    <tr>
                      <td>{issues.issue_date}</td>
                      <td>{ issues.code}</td>
                      {(hasRole([ROLE_ADMIN])) &&
                      <td>{issues.lawyer_id}</td>
    } 
    {(hasRole([ROLE_ADMIN])) &&
                      <td>{issues.name}</td>
                      }       
                      <td>{issues.subject}</td>
                      <td>{issues.description}</td>
                  
                      <td >{issues.issue_status}</td>
                    <td class="row"> 
                   
                    <div style={{paddingRight:'10px'}}><a href={'#/chat/'+issues.code+'/'+((issues.name==null)?"Admin":issues.name)+'/'+((issues.lawyer_id==null)?"0":issues.lawyer_id)+'/'+issues.id+'/'+issues.issue_status} className="btn btn-sm btn-primary"><i className='fa fa-comment'></i>  Replies</a></div> 
                   {issues.issue_status=="Open"? <div><a onClick={() => this.closeIssue(issues.id)} className="btn btn-sm btn-danger"><i className='fa fa-ban'></i>  Close Issue</a></div>
    :""} 
      </td></tr>)}
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


{/* 
              <table className="table table-striped table-responsive">
                    <thead>
                        <tr>
                          
                            <th>Issue date</th>
                            <th>Issue no</th>
                            <th>Lawyer Id</th>
                            <th>Lawyer Name</th>
                            <th>Subject</th>
                            <th>Description</th>
                            <th hidden>Status</th>
                          
                            <th>Action</th>
                        </tr>
                    
                    </thead>
                    <tbody>
                    { this.state.issues.map((issues, index) =>
                    <tr>
                      <td>{issues.issue_date}</td>
                      <td>{ issues.code}</td>
                      <td>{issues.lawyer_id}</td>
                      <td>{issues.name}</td>
                      <td>{issues.subject}</td>
                      <td>{issues.description}</td>
                  
                      <td hidden>{issues.issue_status}</td>
                    <td class="row"> 
                   
                    <div style={{paddingRight:'10px'}}><a href={'#/chat'} className="btn btn-sm btn-primary"><i className='fa fa-comment'></i>  Replies</a></div> 
                    <div><a href={'#/issues'} onClick={() => this.closeIssue(issues.id)} className="btn btn-sm btn-danger"><i className='fa fa-ban'></i>  Close Issue</a></div>
                   
      </td></tr>)}
                </tbody>
                </table>   */}
            </div>
        )
    }
}
