import React, { Component } from 'react'
import { Link } from 'react-router-dom'; 
import { connect } from 'react-redux';
//import fileDownload from 'js-file-download';
//import Slider from 'react-slick';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import { BASE } from "../actions";
import { logout } from '../actions/auth_actions';
//import GoogleMap from "../googlemap/googlemap";
export default class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
          input: {},
          errors: {},
          faq:''
        };
     
      }
      componentDidMount(){
        const api = axios.create({
            baseURL: BASE
          });
        api.get('/faq')
          .then(res => {
            const faq = res.data;
            this.setState({faq});
          }) 
      }
      handleLogout() {
        
        logout(); 
        
      }
      checkIfLoggedIn() {
     
        if (localStorage.getItem('userData')) {
            return (
                
                   <div 
            className="col-9  text-right my-3 align-self-center 
                       login_button_super_div"
       >
                      <Link 
                          to="/dashboard" 
                          className=""
                          style={{ color: 'black',padding:'10px' }}
                      >
                          {JSON.parse(localStorage.getItem('userData')).user.name}
                      </Link> 
                      <Link  to="/" 
                          onClick={this.handleLogout}  className="download_btn_frontpage mt-1
                           btn btn-danger btn-round no-borderrr">Logout</Link>
                      
                    </div>
               
            ); 
        }
        return (
            <div 
            className="col-9  text-right my-3 align-self-center 
                       login_button_super_div"
       >
           <Link to="registration" className="download_btn_frontpage mt-1 mx-1
                           btn btn-success btn-round no-borderrr">Sign Up</Link>
          <Link to="login" className="download_btn_frontpage mt-1
                           btn btn-success btn-round no-borderrr">Login</Link>
        </div>
              );
    }    
      
         
    renderSectionOne() {
        const mystyle = {
            color: "white",
            background: "rgb(255, 255, 255)",

            fontFamily: "Arial"
          };
        return (
             <section className="section_one bg-gradient-primary1">
                 <div className="header" style={ mystyle}>
                 <div className="container">
                 <div className="row mb-0 pb-0">
                         <div className="col-3 text-left borderrrrr my-1" style={{ zIndex: 222 }}>
                             <Link to='/'>
                             <img 
                                  className="mr-3 borderrr" 
                                  src="images/Case Diary Logo-200x200.png" 
                                  alt="logo" 
                                  width="23%"
                                  style={{width: '70px'}}
                             /> 
                             </Link>
                         </div>
                        
                          { this.checkIfLoggedIn() }
                     </div>
                 </div>
                 </div>
                 <div className="container borderrr">
                     
                     <div className="row borderrr py-5">
                      
                         <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.faq}}></div>
                     </div>
                     
                 </div>
                 <div className="borderrr py-4">
                      
                 </div>
             </section>
        );
    }  
    
      
      
    renderSectionSaven() {
        return (
             <section className="section_six bg_goldenBlue">
                 <div className="container">
                     <div className="row pt-3 pb-3 d-flex justify-content-between">
                     <div className="float-left footer_content">
  
                        <span>&copy; 2021 <a href="https://fountainitservice.com/" target="_blank">Fountain Software & IT Services.</a> | <a href="#/terms">Terms & Conditions </a> | <a href="#/faq">FAQ </a>
                        | Pay Us </span><img
                                src="images/casediary-bKash-p1.png"
                                alt="logo"
                                width="100"
                            />
                    </div>
                        <div className="float-right footer_content pt-4">

                        <a href="https://www.facebook.com/bdcasediary" target="_blank">
                            <i className="pr-2 fab fa-facebook-square fa-lg"/>
                        </a>
                        <a href="https://www.linkedin.com/company/case-diary/" target="_blank">
                            <i className="pr-2 fab fa-linkedin fa-lg"/>
                        </a>
                        <a href="https://twitter.com/case_diary" target="_blank">
                            <i className="fab fa-twitter-square fa-lg"/>
                        </a>
                        <img src="images/positivessl_trust_seal_lg_222x54.png" width="100" className="mx-2" />
                        
                        </div>
                     </div>
                 </div>
             </section>
        );
    }
    render() {
        return (
            <div>
          { this.renderSectionOne() }
          
          { this.renderSectionSaven() }
            </div>
        )
    }
}
