import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import {LOCAL_STORAGE_DATA_KEYNAME} from "../../actions/types"
import Select from 'react-select'
export default class PaymentHistoryList extends Component {
    state = {
        persons: [],
        aquaticCreatures: [],
      }
      handleChange = e =>{
        const api = axios.create({
          baseURL: BASE
        });
        if(e){
        let value = e.target.value;
        
        let lawyer_id = e.target.lawyer_id;
        let id = e.target.id;
        //this.setState({ selectedLawyer: e.id });
        api.get('/payment-status/'+id+'/'+value)
            .then(res => {
              const caseIdNos = res.data;
              this.setState( {caseIdNos} );
                this.fetch(lawyer_id);
                alert('Successfully Updated!!!');
            })
        }
      }
      handleChangeLawyer = e =>{
        const api = axios.create({
          baseURL: BASE
        });
        if(e){
        this.setState({ selectedLawyer: e.id });
        api.get('/getbillhistory-by-user/'+e.id)
            .then(res => {
                const persons = res.data;
                this.setState({ persons });
            
            })
        }
      }
      fetch(lawyer_id){
        const api = axios.create({
            baseURL: BASE
          });
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
        api.get('/getbillhistory-by-user/'+lawyer_id)
          .then(res => {
            const persons = res.data;
            this.setState({ persons });
          })
         
      }
      componentDidMount() {
        //this.fetch();
        const api = axios.create({
            baseURL: BASE
          });
        api.get('/all-lawyers')
        .then(res => {
          const aquaticCreatures = res.data;
          
          this.setState( {aquaticCreatures} );
        
        })
      } 
    render() {
        return (
            <div>
                 <div className="form-group col-md-4">
                  <label>Lawyer</label>
                  {this.aquaticCreatures}
                  <Select 
                  isClearable
                  options={this.state.aquaticCreatures}
                  onChange={this.handleChangeLawyer}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  value={this.selectedLawyer}
                  name="lawyer_id"
                   placeholder={<span className="text-success">Search By Lawyer</span>}
                
                   
                />
                </div>
              <table className="table">
                    <thead>
                        <tr>
                            <td>Month</td>
                            <td>Original Amount</td>
                            <td>Vat 5%</td>
                            <td>Discount</td>
                            <td>Total Amount</td>
                            <td>payment Type</td>
                            <td>payment Status</td>
                            <td>Opt</td>
                        </tr>
                    
                    </thead>
                    <tbody>
                    { this.state.persons.map((payment, index) => 
                    <tr>
                      <td>{payment.payment_date}</td>
                      <td>{parseInt(payment.amount)+parseInt(payment.discount)}</td>
                      <td>{payment.vat}</td>
                      <td>{parseInt(payment.discount)}</td>
                      <td>{payment.amount}</td>
                      <td>{payment.payment_type}</td>
                      <td>{payment.payment_status}</td>
                      <td><select id={payment.id} name="payment_status" onChange={this.handleChange} defaultValue={payment.status}>
                          <option value="1">Due</option>
                          <option value="2">Paid</option>
                          
                          </select></td>
                      </tr>
                    )}
                    </tbody>
                </table>  
            </div>
        )
    }
}
