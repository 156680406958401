import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
export default class PromocodeList extends Component {
    state = {
        promocodes: []
      }
    
      componentDidMount() {
        const api = axios.create({
            baseURL: BASE
          });
        api.get('/promo-codes')
          .then(res => {
            const promocodes = res.data;
            this.setState({ promocodes });
          })
      }           

    render() {
        
        return (
            <div className="table-responsive">
                
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Generate Code</th>
                            <th>Start Date</th>
                            <th>Expire Date</th>
                            <th>Discount(%)</th>
                            <th>Status</th>
                            <th>Is expire</th>
                            <th>Opt</th>
                            </tr>
                    
                    </thead>
                    <tbody>
                    { this.state.promocodes.map(promocode =>
                    <tr>
                        <td>{promocode.generated_code}</td>
                        <td>{promocode.start_date}</td>
                        <td>{promocode.expire_date}</td>
                        <td>{promocode.discount}</td>
                        <td>{promocode.status}</td>
                        <td>{promocode.is_expire}</td>
                        <td><a className="btn btn-sm btn-primary" href={'#/promocode-edit/'+promocode.id}>Edit</a>
                        </td>
                        </tr>)}
                </tbody>
                </table>
           
            </div>
        )
    }
    
}