import React, { Component } from 'react'; 
import { connect } from 'react-redux'; 
import PropTypes from 'prop-types';
//import { addFlashMessage } from '../actions';

export default function (ComposedComponent) {
    class Authenticate extends Component {
        componentWillMount() {
            console.log(this.props.isAuthenticated);
         console.log('utils/requireAuth.js / Authenticate classs_________componentWillMount');  
          if (!this.props.isAuthenticated) {
              /*this.props.addFlashMessage({
                  type: 'error',
                  text: 'You need to login to access this page',
              });*/
              //console.log('=-=-=-= utils/requireAuth.js =-=-=-=--=-=-=-=-=-=-=--=-=');
              //this.context.router.history.push('/login'); 
              window.location.href = '#/login';
          }
        }

        componentWillUpdate(nextProps) {
            if (!nextProps.isAuthenticated) {
                //this.context.router.history.push('/');
                window.location.href = '/';
            }
        }
        
        render() {
          return (
            <ComposedComponent {...this.props} />
          );
        }
      }
   
      
    Authenticate.propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
       // addFlashMessage: PropTypes.func.isRequired,
    };

    Authenticate.contextTypes = {
        router: PropTypes.object.isRequired
    };

    function mapStateToProps(state) {
        return {
            isAuthenticated: state.auth.isAuthenticated
        };
    }  
    return connect(mapStateToProps)(Authenticate); 
}