import setAuthorizationData from '../utils/setAuthorizationData'; 

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export function setCurrentUser(userData) {
  //console.log('actions/auth_actions.js /////w userData======', userData); 
  return {
    type: SET_CURRENT_USER,
    user: userData
  };
}

export function logout() {
    console.log(localStorage.removeItem('userData'));
    localStorage.removeItem('userData');
    /*eslint-enable */
    setAuthorizationData(false);
    (setCurrentUser({})); 
  return dispatch => {
     // console.log('sss');
    /*eslint-disable */
    localStorage.removeItem('userData');
    /*eslint-enable */
    setAuthorizationData(false);
    dispatch(setCurrentUser({})); 
  };
}