import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import {LOCAL_STORAGE_DATA_KEYNAME} from "../../actions/types"

export default class PaymentList extends Component {
    state = {
        persons: []
      }
    
      componentDidMount() {
        const api = axios.create({
            baseURL: BASE
          });
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          api.get('/getbillhistory/'+user_id)
          .then(res => {
            const persons = res.data;
            this.setState({ persons });
          })
      } 
    render() {
        return (
            <div>
              <table className="table">
                    <thead>
                        <tr>
                            <td>Month</td>
                            <td>Original Amount</td>
                            <td>Vat Amount</td>
                            <td>Discount(-)</td>
                            <td>Total Amount</td>
                            <td>Payment Status</td>
                            
                        </tr>
                    
                    </thead>
                    <tbody>
                    { this.state.persons.map((payment, index) => 
                    <tr>
                      <td>{payment.payment_date}</td>
                      <td>{(parseInt(payment.amount)+parseInt(payment.discount))-parseInt(payment.vat)}</td>
                      <td>{payment.vat}</td>
                      <td>{parseInt(payment.discount)}</td>
                      <td>{payment.amount}</td>
                      <td>{payment.payment_status}</td>
                      </tr>
                    )}
                    </tbody>
                </table>  
            </div>
        )
    }
}
