import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import DatePicker from "react-datepicker";
import { hasRole } from '../../utils/hasRoleCheck';
import Select from 'react-select';

import moment from "moment";
import {
    ROLE_ADMIN,
    ROLE_LAWYER,

} from '../../components/common/ConstantValues';
import { Widgets } from '@material-ui/icons';
export default class CaseBackDateReportList extends Component {
    state = {
        cases: [],
        checked: '',
        aquaticCreatures: [],
        caseIdNos: [],
        selectedLawyer:null,
        selectedIDNO:null,
        selectedStartDate: null,
        startDate: null,
        userId: '',
    }

    onChange(e) {
        let value = e.target.value;
        let id = e.target.id;
        const api = axios.create({
            baseURL: BASE
        });

        api.put('/setnotifstatus/'+id+'/'+value)
            .then(res => {
                this.fetch();
                alert('sucessfully Updated!!!');
            })

    }
    handleChange = e =>{
        const api = axios.create({
            baseURL: BASE
        });
        if(e){
            this.setState({ selectedLawyer: e.id });
            api.get('/all-cases-user-id-no/'+e.id)
                .then(res => {
                    const caseIdNos = res.data;
                    this.setState( {caseIdNos} );

                })
        }

    }
    handleChangeCase = e =>{
        if(e){
            this.setState({ selectedIDNO: e.id });
        }
    }
    fetch(){
        const api = axios.create({
            baseURL: BASE
        });
        if(hasRole([ROLE_ADMIN])){
            api.get('/case-back-date-report')
                .then(res => {
                    const cases = res.data.data;
                     this.setState({ cases });
                })
            api.get('/all-lawyers')
                .then(res => {
                    const aquaticCreatures = res.data;

                    this.setState( {aquaticCreatures} );

                })
            api.get('/all-cases-id-no')
                .then(res => {
                    const caseIdNos = res.data;
                    this.setState( {caseIdNos} );

                })


        }else{
            const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
            api.get('/cases/'+user_id+'/0')
                .then(res => {
                    const cases = res.data;
                    this.setState({ cases });
                })
        }
    }
    componentDidMount() {
        this.fetch();

    }
    downloadSample(){
        const parentId = this.state.parentID.id;
        const request = this.props.getSampleBuildingFile(parentId);

        request.then((response) => {

            if (response.payload.data != null) {
                const url =BASE + "/"+ response.payload.data;
                window.open(url,"_blank");
            } else {

            }
        }).catch((error) => {

        });
    }
    handleSubmit = event => {
        event.preventDefault();
        const api = axios.create({
            baseURL: BASE
        });
        const data = new FormData(event.target);
        const user_id = data.get('lawyer_id');
        this.setState({userId:user_id})
        const date = this.state.startDate;
        api.get('/case-back-date-report',{params: {
                user_id:user_id,
                date: date
            }})

            .then(res => {
                const cases = res.data.data;
                this.setState({ cases });
            })
    }
    setStartDate(date) {
        this.setState({
            startDate:moment(date).format("YYYY-MM-DD"),
            params: {
                ...this.state.params,
                startDate: moment(date).format("YYYY-MM-DD")
            },
            selectedStartDate: date,
            url: ''
        }, () => {
            console.log("start date selected")
            console.log(this.state.params)
        });
    }
    handleDownload = (event) => {
        const selected_date = this.state.startDate;
        const user_id = this.state.userId;
        const url =BASE + "/case-back-date-report-download?date="+selected_date+"&user_id="+user_id;
        window.open(url,"_blank");

    };
    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="form-group col-md-4 date-selector">
                            <label className="label">Date</label><br/>
                            <DatePicker className="search-field form-control"
                                        dateFormat="dd MMM yyyy"
                                        placeholderText="Select..."
                                        selected={this.state.selectedStartDate}
                                        onChange={(date) => this.setStartDate(date)}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Lawyer</label>
                            {this.aquaticCreatures}
                            <Select
                                isClearable
                                options={this.state.aquaticCreatures}
                                onChange={this.handleChange}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e.id}
                                value={this.selectedLawyer}
                                name="lawyer_id"
                                placeholder={<span className="text-success">Search By Lawyer</span>}

                            />
                        </div>

                        <label></label>
                        <div className="form-group col-md-1 pt-4"><button type="submit" className="btn btn-primary btn-sm">GO</button></div>
                    </div>

                </form>
                <a href='#/case-back-date-report' onClick={this.handleDownload}  className="btn btn-info btn-sm">Export</a>
                <table className="table table-striped table-responsive">
                    <thead>
                    <tr>
                        <th>SL No.</th>
                        <th>Lawyer Name</th>
                        <th>Case No.</th>
                        <th>Area</th>
                        <th>Type</th>
                        <th>Court</th>
                        <th>Next Date</th>
                        <th>For</th>
                        <th>Party-A</th>
                        <th>Party-B</th>
                        <th>Case Lawyer Name-1</th>
                        <th>Case Lawyer Name-2</th>
                        <th>Last Modified Date</th>
                        <th>Aging</th>
                    </tr>

                    </thead>
                    <tbody>
                    { this.state.cases.map((cases, index) =><tr>
                        <td>{++index}</td>
                        <td>{cases.lawyer_name}</td>
                        <td>{ cases.case_no}</td>
                        <td>{cases.area_name}</td>
                        <td>{cases.case_type}</td>
                        <td>{cases.court_name}</td>

                        <td>{cases.next_date}</td>
                        <td>{cases.case_for}</td>
                        <td>{cases.party_one}</td>
                        <td>{cases.party_two}</td>
                        <td>{cases.advocate_one}</td>
                        <td>{cases.advocate_two}</td>
                        <td>{cases.updated_at}</td>
                        <td>{cases.aging}</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        )
    }
}
