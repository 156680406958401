import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import {LOCAL_STORAGE_DATA_KEYNAME} from "../../actions/types"
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'; 
import { hasRole } from '../../utils/hasRoleCheck';
import {
  ROLE_ADMIN,
  ROLE_LAWYER,

} from '../../components/common/ConstantValues';
import Select from 'react-select'
import { isEmpty } from 'lodash';

export default class LawyerActiveCaseList extends Component {
    state = {
        cases: [],
        checked: '',
        aquaticCreatures: [],
        caseIdNos: [],
        selectedLawyer:null,
        selectedIDNO:null,
        islawyerId:null,
      }
      
      onChange(e) {
        let value = e.target.value;
        
        let id = e.target.id;
        console.log(e.target.id);
        const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
        const api = axios.create({
          baseURL: BASE
        });
        
      api.put('/setnotifstatus/'+user_id+'/'+id+'/'+value)
        .then(res => {
          this.fetch();
          alert('sucessfully Updated!!!');
        })
        
    }
    handleSearch(e){
      let value = e.target.value;
      let id = e.target.name;
      const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
      const api = axios.create({
        baseURL: BASE
      });
     
      api.get('/case-all-active-list-lawyer?user='+user_id+'&search='+value)
        .then(res => {
          const cases = res.data;
          this.setState({ cases });
        })
      
      
    }
    handleChange = e =>{
      const api = axios.create({
        baseURL: BASE
      });
      if(e){
      this.setState({ selectedLawyer: e.id });
      api.get('/all-cases-user-id-no/'+e.id)
          .then(res => {
            const caseIdNos = res.data;
            this.setState( {caseIdNos} );
          
          })
      }
    }
    handleChangeCase = e =>{
     if(e){
      this.setState({ selectedIDNO: e.id });
     }
   
    }
      fetch(){
        const api = axios.create({
          baseURL: BASE
        });
        if(hasRole([ROLE_ADMIN])){
        api.get('/cases')
          .then(res => {
            const cases = res.data;
           // this.setState({ cases });
          })
          api.get('/all-lawyers')
          .then(res => {
            const aquaticCreatures = res.data;
            
            this.setState( {aquaticCreatures} );
          
          })
          /*api.get('/all-cases-id-no')
          .then(res => {
            const caseIdNos = res.data;
            this.setState( {caseIdNos} );
          
          })*/
          
         
        }else{
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          
          api.get('/cases/'+user_id+'/0')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
          api.get('/all-cases-user-id-no/'+user_id)
          .then(res => {
            const caseIdNos = res.data;
            this.setState( {caseIdNos} );
          
          })
        }
        const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
    api.get('/users-lawyer/'+user_id)
    .then(res => {
      const lawyerId = res.data;
      this.setState( { islawyerId:lawyerId }   );
     
    });
      }
      componentDidMount() {
        this.fetch();
      
      } 
      downloadSample(){
        const parentId = this.state.parentID.id;
        const request = this.props.getSampleBuildingFile(parentId);
       
        request.then((response) => {
         
          if (response.payload.data != null) {
            const url =BASE + "/"+ response.payload.data;
            window.open(url,"_blank");
          } else {
            
          }
        }).catch((error) => {
        
        });
      }
      handleSubmit = event => {
        event.preventDefault();
        const api = axios.create({
          baseURL: BASE
        });
        const data = new FormData(event.target);
        
        //const user_id = JSON.parse(localStorage.getItem('userData')).user.id;  
        const user_id = data.get('lawyer_id');
        const case_id_no = data.get('case_id_no');
        var ids = user_id;
        var case_ids = case_id_no;
        if(isEmpty(ids)){
          ids = 0;
        }
        if(isEmpty(case_ids)){
          case_ids = 0;
        }
        //{user_id}/{case_id}/{is_close}
        api.get('/cases/user/'+ids+'/'+case_ids+'/0')
        .then(res => {
          const cases = res.data;
          this.setState({ cases });
        })
      }
      onChangeClose(e) { 
        const user_id = JSON.parse(localStorage.getItem('userData')).user.id;  
        let value = e.target.value;
        let id = e.target.id;
        
        const api = axios.create({
          baseURL: BASE
        });
        
      api.put('/setclosestatus/'+user_id+'/'+id+'/'+value)
        .then(res => {
          this.fetch();
          alert('sucessfully Updated!!!');
        })
        
    }
    render() {
        return (
            <div>
               <form onSubmit={this.handleSubmit}>
                 <div className="row">
                 {(hasRole([ROLE_ADMIN])) &&
                
                 <div className="form-group col-md-4">
                  <label>Lawyer</label>
                  {this.aquaticCreatures}
                  <Select 
                  isClearable
                  options={this.state.aquaticCreatures}
                  onChange={this.handleChange}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  value={this.selectedLawyer}
                  name="lawyer_id"
                   placeholder={<span className="text-success">Search By Lawyer</span>}
                
                />
               
                </div>
                 } {(hasRole([ROLE_LAWYER])) &&
                 <input type="hidden" name="lawyer_id" value={JSON.parse(localStorage.getItem('userData')).user.id}/>
                
                 }
                 <div className="form-group col-md-4">
                  <label>Case ID NO</label>
                  <Select 
                 isClearable
                  options={this.state.caseIdNos}
                  onChange={this.handleChangeCase}
                  getOptionLabel={e => e.case_id_no}
                  getOptionValue={e => e.id}
                  value={this.selectedIDNO}
                  name="case_id_no"
                   placeholder={<span className="text-success">Search By Case ID NO</span>}
                
                />
                </div>
                <label></label>
                <div className="form-group col-md-1 pt-4"><button type="submit" className="btn btn-primary btn-sm">GO</button></div>
                </div>
               
               </form>
               <div className="row py-2">
                 <label className="col-md-1 py-2">Search:</label>
                 <div className="col-md-4">
               <input type="text" className="form-control" placeholder="Search" onChange={this.handleSearch.bind(this)} name="search"></input>
               </div>
               </div>
               {(!this.state.islawyerId) && <a href="#/add-case" className="btn btn-primary float-right btn-sm">Add New Case </a>}
               {(this.state.islawyerId) && <div> <p className="disabled btn btn-primary ">Please Add Lawyer ID At First!</p> <a href="#/edit-profile">Click Here</a><a href="#" className="disabled btn btn-primary float-right btn-sm">Add New Case </a></div>}
               <table className="table table-striped table-responsive">
                    <thead>
                        <tr>
                          <th>SL No.</th>
                            <th>Case ID</th>
                            <th style={{width:'5%'}}>Case NO</th>
                            <th>Area</th>
                            <th>Type</th>
                            <th>Court</th>
                            <th>Adv 1</th>
                            <th>Adv 2</th>
                            <th>Pre Date</th>
                            <th>Next Date</th>
                            <th>For </th>
                            <th>Result</th>
                            {(hasRole([ROLE_LAWYER])) &&<th>Opt</th> }
                        </tr>
                    
                    </thead>
                    <tbody>
                    { this.state.cases.map((cases, index) =><tr>
                      <td>{++index}</td>
                      <td>{cases.case_id_no}</td>
                      <td>{ cases.case_no}</td>
                      <td>{cases.area_name}</td>
                      <td>{cases.case_type}</td>
                      <td>{cases.court_name}</td>
                      <td>{cases.advocate_one}</td>
                      <td>{cases.advocate_two}</td>
                      <td>{cases.previous_date}</td>
                      <td>{cases.next_date}</td>
                      <td>{cases.case_for}</td>
                      <td>{cases.result}</td>
                      <td>
                      {(hasRole([ROLE_LAWYER])) && <div><FormGroup>
                  <FormControlLabel
        control={<Switch
          id={cases.id}
          onChange={this.onChange.bind(this)}
          value={(cases.is_notify==1)?0:1}
          checked= {(cases.is_notify==1)?'checked':''}
        color="primary"
      />}
        label="Notification"
      />
   
    </FormGroup>
    <FormGroup>
      <FormControlLabel
        control={<Switch
          id={cases.id}
          onChange={this.onChangeClose.bind(this)}
          value={1}
         
        color="primary"
      />}
        label="Case-Closed" />
      </FormGroup></div> } </td>
                      </tr>)}
                </tbody>
                </table> 
            </div>
        )
    }
}
