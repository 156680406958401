import React, { Component } from 'react'
import { Link } from 'react-router-dom'; 
import { connect } from 'react-redux';
//import fileDownload from 'js-file-download';
//import Slider from 'react-slick';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import { BASE } from "../actions";
import { logout } from '../actions/auth_actions';
//import GoogleMap from "../googlemap/googlemap";
export default class ForntPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          input: {},
          errors: {}
        };
        this.handleLogout = this.handleLogout.bind(this); 
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      handleLogout() {
        
        logout(); 
        
      }
      checkIfLoggedIn() {
     
        if (localStorage.getItem('userData')) {
            return (
                
                   <div 
            className="col-9  text-right my-3 align-self-center 
                       login_button_super_div"
       >
                      <Link 
                          to="/dashboard" 
                          className=""
                          style={{ color: 'black',padding:'10px' }}
                      >
                          {JSON.parse(localStorage.getItem('userData')).user.name}
                      </Link> 
                      <Link  to="/" 
                          onClick={this.handleLogout}  className="download_btn_frontpage mt-1
                           btn btn-danger btn-round no-borderrr">Logout</Link>
                      
                    </div>
               
            ); 
        }
        return (
            <div 
            className="col-9  text-right my-3 align-self-center 
                       login_button_super_div"
       >
           <Link to="registration" className="download_btn_frontpage mt-1 mx-1
                           btn btn-success btn-round no-borderrr">Sign Up</Link>
          <Link to="login" className="download_btn_frontpage mt-1
                           btn btn-success btn-round no-borderrr">Login</Link>
        </div>
              );
    }    
      handleChange(event) {
        let input = this.state.input;
        input[event.target.name] = event.target.value;
      
        this.setState({
          input
        });
      }
         
      handleSubmit(event) {
        event.preventDefault();
      
        if(this.validate()){
      
            const post = this.state.input;
            const api = axios.create({
                baseURL: BASE
              });
              api.post(`contact`, { post })
            .then(res => {
             
      
              let input = {};
              input["name"] = "";
              input["email"] = "";
              input["phone"] = "";
              input["message"] = "";
              this.setState({input:input});
      
              alert('Successfully Sent!!!.');
      
            })
       
        }
      }
      
      validate(){
          let input = this.state.input;
          let errors = {};
          let isValid = true;
      
          if (!input["name"]) {
            isValid = false;
            errors["name"] = "Please enter your name.";
          }
          if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your Email.";
          }
          if (!input["phone"]) {
            isValid = false;
            errors["phone"] = "Please enter your Phone.";
          }
      
          if (!input["message"]) {
            isValid = false;
            errors["message"] = "Please enter your Message.";
          }
      
          this.setState({
            errors: errors
          });
      
          return isValid;
      }
         
    renderSectionOne() {
        const mystyle = {
            color: "white",
            background: "rgb(255, 255, 255)",

            fontFamily: "Arial"
          };
        return (
             <section className="section_one bg-gradient-primary">
                 <div className="header" style={ mystyle}>
                 <div className="container">
                 <div className="row mb-0 pb-0">
                         <div className="col-3 text-left borderrrrr my-1" style={{ zIndex: 222 }}>
                             <Link to='/'>
                             <img 
                                  className="mr-3 borderrr" 
                                  src="images/Case Diary Logo-200x200.png" 
                                  alt="logo" 
                                  width="23%"
                                  style={{width: '70px'}}
                             /> 
                             </Link>
                         </div>
                        
                          { this.checkIfLoggedIn() }
                     </div>
                 </div>
                 </div>
                 <div className="container borderrr">
                     
                     <div className="row borderrr py-5">
                         <div className="col-md-7 text-left text-white button_outline borderrr">
                             <h1 className=" mt-5">
                             Case Record Maintained Easily  <br /> into Case Diary
                              </h1>
                             <p className="my-4">
                             Get all your case records.
                             </p>
                             <a 
                                  onClick={this.downloadAPK}
                                  href="https://play.google.com/store/apps/details?id=com.fountainit.casediaryandroid" 
                                  className="download_btn_frontpage mt-5
                                             btn btn-dark btn-round no-borderrr" target="_blank"
                             >
                                  Download Now
                             </a>
                          </div>
                          <div className="col-5 text-right borderrr">
                             
                          </div>
                     </div>
                     
                 </div>
                 <div className="borderrr py-4">
                      
                 </div>
             </section>
        );
    }  
    renderSectionTwo() {
        return (
          <section className="section_two ">
              <div className="container">
                  <div className="row my-5">
                      <div className="col-md-5 text-left borderrrrr">
                          <div className="fp_phone_image">
                              <img 
                                  className="img-fluid" 
                                  src="images/law-1-1.png"
                                  alt="mobile" 
                                  width="100%"
                              />
                          </div>
                      </div>
                      <div 
                          className="col-md-7 d-flex justify-content-center 
                                      align-items-center borderrr"
                      >
                          <div className="button_outline text-justify">
                              
                              <h3>WHY CHOOSE CASE DIARY</h3>
                              <p>
                              Case Diary is an online mobile and web application of the Case Records of legal professionals which allow you to use your data even outside your office and you don’t need to carry old fashioned bulky diaries with you. Case Diary helps Lawyers/Litigants Professional on remembering the upcoming case schedules also help you to find the case details very easily. Every day we upload thousands of cases of different Court to Case Diary that lessens your hassle and effort of collecting the data. Also, you can add your cases very easily.                       
                                                         
                            </p>
                            <p>It is easy to use and it is available to the legal professionals ANY TIME, ANY DAY and on ANY DEVICE.  Let’s go Digital.</p>
                              {/*<Link */}
                                  {/*to="#1" */}
                                  {/*className="similar_btn_frontpage  */}
                                             {/*btn btn-round no-borderrr"*/}
                              {/*>*/}
                                  {/*Buy Now*/}
                              {/*</Link>*/}
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        );
    }  
    renderSectionThree() {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            speed: 1800,
            cssEase: 'linear',
            //centerMode: true,
            centerPadding: '60px',
            // slidesToShow: 1,
            // slidesToScroll: 1
        };
        return (
            <section className="section_three">
                 <div className="container">
                     <div className="row">
                        <div className="col-md-12 text-center">
                        <h3>OUR FEATURE</h3>
                        </div>
                          <div 
                              className="col-12 d-flex justify-content-center 
                                         align-items-center borderrrrr"
                          >
                             <div className="row mt-5">
                             <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <div className="card">
                                        <div className="card-block block-1 text-center px-2 py-5">
                                        <img
                                                    src="images/icon.png"
                                                    alt="logo"
                                                    width="25%"
                                                />
                                            <h3 className="card-title">Case Details</h3>
                                            <p className="card-text">Get all the details like case number, case location, opponent name, party names, Court No, Next Date and result</p>
                                           
                                         </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <div className="card">
                                        <div className="card-block block-1 text-center px-2 py-5">
                                        <img
                                                    src="images/rsz_on-time-icon-28.png"
                                                    alt="logo"
                                                    width="25%"
                                                />
                                            <h3 className="card-title">Time Saver Notification</h3>
                                            <p className="card-text">Get a notification while a new date is given of a case, a new case is uploaded or a case is updated</p>
                                         </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                    <div className="card">
                                        <div className="card-block block-1 text-center px-2 py-5">
                                        <img
                                                    src="images/rsz_941-9412457_png-file-svg-cloud-storage-icon-free.png"
                                                    alt="logo"
                                                    width="25%"
                                                />
                                            <h3 className="card-title">Cloud Storage</h3>
                                            <p className="card-text">Don’t need to worry about losing your data. All your data will be kept in a cloud storage with the complete history of cases</p>
                                            
                                            </div>
                                    </div>
                                </div>
                                
			                </div>
                            
                          </div>
                          
                     </div>
                 </div>
             </section>
        );
    }
    renderSectionFour() {
        return (
             <section className="section_four">
                 <div className="container">
                     <div className="row my-5">
                          
                     </div>
                 </div>
             </section>
        );
    }
    renderSectionFive() {
        return (
             <section className="section_five bg_goldenBlue">
                 <div className="container">
                      <div className="text-white">
                          <div className=" d-flex justify-content-center">
                              <div className="my-2">
                                  <h3 className="my-5 text-uppercase"> How it works </h3>
                              </div>
                           </div>
                          <div className="row mb-5 d-flex justify-content-between sub_content pb-5">
                              <div className="col-md-3 text-center">
                                  <img
                                      src="images/png-transparent-computer-icons-arrow-button-next-step-logo-arrow-next-step.png"
                                      alt="logo"
                                      width="15%"
                                  />
                                  <h5>Step One</h5>
                                  <p>Download the mobile application from Play Store</p>
                              </div>
                              <div className="col-md-3 text-center">
                              <img
                                      src="images/png-transparent-computer-icons-arrow-button-next-step-logo-arrow-next-step.png"
                                      alt="logo"
                                      width="15%"
                                  />
                                  <h5>Step Two</h5>
                                  <p>Complete the registration with your Lawyer ID and required information</p>
                              </div>
                              <div className="col-md-3 text-center">
                              <img
                                      src="images/png-transparent-computer-icons-arrow-button-next-step-logo-arrow-next-step.png"
                                      alt="logo"
                                      width="15%"
                                  />
                                  <h5>Step Three</h5>
                                  <p>Complete your monthly subscription. You can subscribe up to six months at one time</p>
                              </div>
                              <div className="col-md-3 text-center">
                              <img
                                      src="images/png-transparent-computer-icons-arrow-button-next-step-logo-arrow-next-step.png"
                                      alt="logo"
                                      width="15%"
                                  />
                                  <h5>Step Four</h5>
                                  <p>
                                  Wait for 48 hours you will find all your next month cases first, then gradually all the cases
                                  </p>
                              </div>
                          </div>
                      </div>
                 </div>
             </section>
        );
    }
      renderSectionSix() {
          return (
              <section className="section_five">
                  <div className="container">
                      <div className="row mt-5 mb-5">
                          <div className="col-md-6 justify-content-between">
                              <h2> CONTACT US </h2>
                              <div className="row">
                                  <div className="col-md-12 mt-5">
                                      <h5><strong>Case Diary</strong></h5>
                                      <h6>554 Rd No. 9, Adabor</h6>
                                      <h6>Dhaka, Bangladesh</h6>
                                      <h6>Phone: +8801728850586</h6>
                                      <h6>Email: <a href="mailto:support@casediary.com.bd" target="_top">support@casediary.com.bd</a></h6>
                                  </div>
                              </div>
  
                          </div>
                          <div className="col-md-6">
                          <h4> GET IN TOUCH </h4>
                            <div className="py-3">
                            <form onSubmit={this.handleSubmit}>
                           
          <div className="form-group row">
            
            <input 
              type="text" 
              name="name" 
              value={this.state.input.name}
              onChange={this.handleChange}
              className="form-control col-md-12" 
              placeholder="Enter Your name" 
              id="name" />
  
              <div className="text-danger">{this.state.errors.name}</div>
          </div>
          <div className="form-group row">
          <input 
              type="email" 
              name="email" 
              value={this.state.input.email}
              onChange={this.handleChange}
              className="form-control col-md-12" 
              placeholder="Enter Your Email" 
              id="email" />
  
              <div className="text-danger">{this.state.errors.email}</div>
          </div>
          <div className="form-group row">
          <input 
              type="text" 
              name="phone" 
              value={this.state.input.phone}
              onChange={this.handleChange}
              className="form-control col-md-12" 
              placeholder="Enter Your Phone" 
              id="phone" />
  
              <div className="text-danger">{this.state.errors.phone}</div>
          </div>
          <div className="form-group row">
           
            <textarea 
              name="message"
              value={this.state.input.message} 
              onChange={this.handleChange}
              placeholder="Write Here Your Message.."
              className="form-control" />
  
              <div className="text-danger">{this.state.errors.message}</div>
          </div>
              
          <input type="submit" value="Submit" className="btn btn-success" />
        </form>
                                 
                             </div>
                          </div>
                      </div>
                  </div>
              </section>
          );
      }
    renderSectionSaven() {
        return (
             <section className="section_six bg_goldenBlue">
                 <div className="container">
                     <div className="row pt-3 pb-3 d-flex justify-content-between">
                         <div className="float-left footer_content">
  
                         <span>&copy; 2021 <a href="https://fountainitservice.com/" target="_blank">Fountain Software & IT Services.</a> | <a href="#/terms">Terms & Conditions </a> | <a href="#/faq">FAQ </a>
                        | Pay Us </span><img
                                src="images/casediary-bKash-p1.png"
                                alt="logo"
                                width="100"
                            />
                         </div>
                         <div className="float-right footer_content pt-4">
                         
                             <a href="https://www.facebook.com/bdcasediary" target="_blank">
                                 <i className="pr-2 fab fa-facebook-square fa-lg"/>
                             </a>
                             <a href="https://www.linkedin.com/company/case-diary/" target="_blank">
                                 <i className="pr-2 fab fa-linkedin fa-lg"/>
                             </a>
                             <a href="https://twitter.com/case_diary" target="_blank">
                                 <i className="fab fa-twitter-square fa-lg"/>
                             </a>
                             <img src="images/positivessl_trust_seal_lg_222x54.png" width="100" className="mx-2" />
                             
                         </div>
                     </div>
                 </div>
             </section>
        );
    }
    render() {
        return (
            <div>
          { this.renderSectionOne() }
          { this.renderSectionTwo() }
          { this.renderSectionThree() }
          { this.renderSectionFour() }
          { this.renderSectionFive() } 
          { this.renderSectionSix() }
          { this.renderSectionSaven() }
            </div>
        )
    }
}
