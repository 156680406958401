import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { hasRole } from '../../utils/hasRoleCheck';
import {
    ROLE_ADMIN,
    ROLE_LAWYER,
  
  } from '../../components/common/ConstantValues';
export default class ServiceConfigOpt extends Component {
    state = {
        cases: [],
        checked: '',
        is_email:0,
        is_sms:0
      }
      
      onChange(e) {   
        let checked = e.target.checked;
        let id = e.target.id;
        let is_email_val=this.state.is_email;
        let is_sms_val=this.state.is_sms;
        if(id=="email")
        {
          is_email_val=checked?1:0;
          this.setState({is_email:is_email_val})
          console.log("is_email   "+this.state.is_email)
        }
        else if(id=="sms")
         {
          is_sms_val=checked?1:0;
            this.setState({is_sms:is_sms_val})
            console.log("is_sms  "+this.state.is_sms)
         }
        console.log("is_email"+this.state.is_email)
        console.log("is_sms"+this.state.is_sms)
        const api = axios.create({
          baseURL: BASE
        });
        if(localStorage.getItem('userData'))
    {
      const user_id = JSON.parse(localStorage.getItem('userData')).user.id;

      api.post('/notification-setting',{
        user_id:user_id,
        is_email:is_email_val,
        is_sms:is_sms_val
      })
        .then(res => {
         console.log(res)
          alert('sucessfully Updated!!!');
        })
      }
    }
  //   onChangeClose(e) {   
  //     let checked = e.target.checked;
  //     let id = e.target.id;
      
  //     const api = axios.create({
  //       baseURL: BASE
  //     });
      
  //   api.put('/setclosestatus/'+id+'/'+value)
  //     .then(res => {
  //       this.fetch();
  //       alert('sucessfully Updated!!!');
  //     })
      
  // }
    fetchsetting(){
      const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
      const api = axios.create({
        baseURL: BASE
      });
      api.get('/notification-setting/'+user_id).then(res => {
        //console.log("settings::: "+res.data.is_email)
        const settings = res.data;
        this.setState({ is_email:settings.is_email,is_sms:settings.is_sms });

      })
    }
      fetch(){
        const api = axios.create({
          baseURL: BASE
        });
        if(hasRole([ROLE_ADMIN])){
        api.get('/cases')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }else{
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          api.get('/cases/'+user_id+'/0')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }
      }
      componentDidMount() {
        this.fetchsetting();
      } 
    
    render() {
     
        return (
            <div>
               <FormGroup>
                   
                   <FormControlLabel
                     control={<Switch
                        id="email"
                        onChange={this.onChange.bind(this)}
                      checked={this.state.is_email==1?'checked':''}
                      // checked= {(cases.is_notify==1)?'checked':''}
                     color="primary"
                   />}
                     label="Email"
                   />
                <FormControlLabel
                     control={<Switch
                        id="sms"
                        onChange={this.onChange.bind(this)}
                        checked={this.state.is_sms==1?'checked':''}
                      // checked= {(cases.is_notify==1)?'checked':''}
                     color="primary"
                   />}
                     label="SMS"
                   />
                 </FormGroup> 
            </div>
        )
    }
}
