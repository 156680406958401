import {combineReducers,createStore } from 'redux';
import AuthReducer from './AuthReducer';
import RoleReducer from './AuthReducer';
export function updateObject(oldObject, newValues) {
  return Object.assign({}, oldObject, newValues);
}
/*just exported from here, not used in this file, but used in other reducer files.*/
export function hasError(state, action) {
  if (action.type === '@@redux--form/SET_SUBMIT_FAILED') {
    return { ...state }; /* FOR `redux form validation console error` NOT SHOWING */
  }
  return { ...state, errormessage: action.payload.message };
}
  
const rootReducer = combineReducers({
    auth:AuthReducer,
    role:RoleReducer
});
//const store = createStore(rootReducer)
//console.log(store.getState());
export default rootReducer;