import React, { Component,useState } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { MainRoute } from './routes';
import store from './config/store';
import setAuthorizationData from './utils/setAuthorizationData'; 
import { setCurrentUser } from './actions';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import { getToken, messaging,onBackgroundMessageListener } from './firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Drawer from './components/shared/DrawerMenu'
import AppBarMain from './components/shared/AppBarMain';
getToken();


function showToast(title,body){
  const Msg = ({ closeToast }) => (
    <div>
      <h4> {title}</h4>
    
     <p className="text-white"> {body} </p>
    </div>
  )
  console.log('toast')
  toast.success(Msg,{autoClose:3000})
}


 //Navbar.setState({counter:88})
 

if (localStorage.userData) {
 
  setAuthorizationData (JSON.parse(localStorage.userData) ); 
  store.dispatch( setCurrentUser (JSON.parse(localStorage.userData)) ); 
}
localStorage.setItem('badgecount',0)
function App() {
  
  //init().counter
  var cnt=0;
  cnt=localStorage.getItem('badgecount')
  var [counter,setCounter]=useState(cnt)
  var [notification,setNotification]=useState([{
    title:'',
    body:''
  }]);
new Promise((resolve) => {
  messaging.onMessage((payload) => {
    console.log('cccccccc')
    resolve(payload);
    var cnt=0
    if(localStorage.getItem('badgecount'))
    {
       cnt=localStorage.getItem('badgecount');
       cnt++
    localStorage.setItem('badgecount',cnt);
    }
    else
    localStorage.setItem('badgecount',0);
    
    
    console.log('cnt')
    console.log(cnt)
      setCounter(cnt);
     
   
  });
  
}).then(payload => {
  setNotification({title: payload.notification.title, body: payload.notification.body})
  showToast(payload.notification.title,payload.notification.body);
  console.log('counter')
  console.log(counter)
console.log('xxxfffffff');
//this.setState({show:true})
//this.setState({notification:{title: payload.notification.title, body: payload.notification.body}})
console.log(payload);

}).catch(err => console.log('failed: ', err));

  //console.log('userdataaaaaaaaa')
  //console.log(JSON.parse(store.localStorage.CurrentUser))
  if (localStorage.getItem('userData')) {
  return (
    <div className="container-scroller">
    
      <ToastContainer/>

    

      
       
      <Provider store={store}>
        <MainRoute counter={counter}/>
    </Provider>
         
       
 

  </div>
   
  );
}
else
{
  return (
    
    <Provider store={store}>
        <MainRoute />
    </Provider>
    
  );
}
}

export default App;
