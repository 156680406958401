import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import {LOCAL_STORAGE_DATA_KEYNAME} from "../../actions/types"
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'; 
import { hasRole } from '../../utils/hasRoleCheck';
import {
  ROLE_ADMIN,
  ROLE_LAWYER,

} from '../../components/common/ConstantValues';

export default class NotificationList extends Component {
    state = {
        cases: []
      }
      onChange(e) {
        let value = e.target.value;
        
        let id = e.target.id;
        console.log(e.target.id);
        const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
        const api = axios.create({
          baseURL: BASE
        });
        
      api.put('/setnotifstatus/'+user_id+'/'+id+'/'+value)
        .then(res => {
          //this.fetch();
          alert('sucessfully Updated!!!');
          this.fetch();
        })
        
    }
      handleSearch(e){
        let value = e.target.value;
        let id = e.target.name;
        const api = axios.create({
          baseURL: BASE
        });
        if(hasRole([ROLE_ADMIN])){
        api.get('/case-all-notify-off-list?search='+value)
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }else{
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          api.get('/notifcases/'+user_id+'/0')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }
      }
      fetch(){
        const api = axios.create({
          baseURL: BASE
        });
        if(hasRole([ROLE_ADMIN])){
        api.get('/case-all-notify-off-list')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }else{
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          api.get('/notifcases/'+user_id+'/0')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }
      }
      componentDidMount() {
        this.fetch();
      } 
    render() {
        return (
            <div>
              <div className="">
                 <label>Search:</label> 
               <input type="text" onChange={this.handleSearch.bind(this)} name="search"></input>
               </div>
                <table className="table table-striped table-responsive">
                    <thead>
                        <tr>
                        <th style={{width:"5%"}}>Case ID</th>
                            <th>Case NO</th>
                            <th>Area</th>
                            <th>Type</th>
                            <th>Court</th>
                            
                            <th>Next date</th>
                            <th>For </th>
                            <th>Result</th>
                            <th>Action</th>
                        </tr>
                    
                    </thead>
                    <tbody>
                    { this.state.cases.map(cases =><tr><td>{cases.case_id_no}</td><td>{ cases.case_no}</td><td>{cases.area_name}</td><td>{cases.case_type}</td><td>{cases.court_name}</td><td>{cases.next_date}</td><td>{cases.case_for}</td><td>{cases.result}</td><td>
                      {(hasRole([ROLE_ADMIN])) &&
                    <div><a href={'#/edit-case/'+cases.id}><i className='fa fa-edit'></i> Edit</a></div>}
                    <FormGroup>
                  <FormControlLabel
        control={<Switch
          id={cases.id}
          onChange={this.onChange.bind(this)}
          value={(cases.is_notify==1)?0:1}
          checked= {(cases.is_notify==1)?'checked':''}
        color="primary"
      />}
        label="Notification"
      />
   
    </FormGroup></td></tr>)}
                   </tbody>
                </table> 
            </div>
        )
    }
}
