import React, {useState, useEffect} from 'react'
import {Container,Button,CssBaseline,Avatar,
    Typography,TextField,Grid,Link,Checkbox,Box,
    FormControlLabel} from '@material-ui/core';
    import { makeStyles } from '@material-ui/core/styles';
    import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import axios from 'axios';
import { BASE } from "../actions";
    const useStyles = makeStyles((theme) => ({
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    }));
        
    function Copyright() {
      return (
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}{new Date().getFullYear()}
          {'.'}
          <Link color="inherit" href="https://fountainitservice.com">
            Fountain Software & IT Services
          </Link>{' '}
          
        </Typography>
      );
    }
    
    
 const ForgetPassword = () => {
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(null);
  const [pin, setPin] = useState(null);
  const [reset, setreset] = useState(null);
  const [phone, setPhone] = useState(null);
  const [defaultpin, setdefaultpin] = useState(null);
  const [pass, setpass] = useState(null);
  const [repass, setrepass] = useState(null);
        const props = { backgroundColor: 'black', color: 'white' };
      
        const classes = useStyles(props);
        const handleLogin = (e) => {
     
          setLoad(true);
          e.preventDefault();
    
          const { history } = e;
         
          const data = new FormData(e.target);
          console.log(data.values());
          const api = axios.create({
            baseURL: BASE
          });
          api.post("/password/email", data)
            .then((res) => {
              if(res.data==true){
                setLoad(false);
                setPin(res.data);
                setdefaultpin('');
              }else{
                  alert('Sorry, did not match!');
              }
              //console.log(res.data);
              
              
            })
            .catch((error) => {
              setLoad(false);
              setError(error.response.data.loginmsg);
            });
        };
        const handlePin = (e) => {
     
          setLoad(true);
          e.preventDefault();
    
          const { history } = e;
         
          const data = new FormData(e.target);
          console.log(data.values());
          const api = axios.create({
            baseURL: BASE
          });
          api.post("/pin-check", data)
            .then((res) => {
              //console.log(res.data);
              setLoad(false);
              setreset(res.data.status);
              setPhone(res.data.phone);
             
            })
            .catch((error) => {
              setLoad(false);
              setError(error.response.data.loginmsg);
            });
        };
        const handlePassword = (e) => {
     
          setLoad(true);
          e.preventDefault();
    
          const { history } = e;
         
          const data = new FormData(e.target);
          console.log(data.values());
          const api = axios.create({
            baseURL: BASE
          });
          api.post("/reset-pass", data)
            .then((res) => {
              //console.log(res.data);
              setLoad(false);
              alert('success');
              window.location.href = '#/login';
             
            })
            .catch((error) => {
              setLoad(false);
              setError(error.response.data.loginmsg);
            });
        };
        if(reset==true){
          return(
            <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Link href='/'>
                             <img 
                                  className=" borderrr" 
                                  src="images/Case Diary Logo-200x200.png" 
                                  alt="logo" 
                                
                                  style={{width: '80px'}}
                             /> 
                             </Link>
        <Typography component="h1" variant="h5">
          Enter Your New Password
        </Typography>
        <form onSubmit={handlePassword} className={classes.form} noValidate>
          <input type="hidden" name="phone" defaultValue={phone}/>
          <TextField
          type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="New Password"
            name="password"
           
          />
          <TextField
          type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password_confirmation"
            label="Confirm Password"
            name="password_confirmation"
            
          />
        
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Confirm
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/" variant="body2">
                Login
              </Link>
            </Grid>
        
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
          );
        }
        else if(pin==true){
          return(
            <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Link href='/'>
                             <img 
                                  className=" borderrr" 
                                  src="images/Case Diary Logo-200x200.png" 
                                  alt="logo" 
                                
                                  style={{width: '80px'}}
                             /> 
                             </Link>
                             <p>The PIN has been sent your phone or Email. </p>
        <Typography component="h1" variant="h5">
         
          Enter Your Pin
        </Typography>
        <form onSubmit={handlePin} className={classes.form} noValidate>
        <input type="hidden" />
          <TextField
            type="text"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="pin"
            label="PIN"
            name="pin"
            
          />
          
        
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#/login" variant="body2">
                Login
              </Link>
            </Grid>
        
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
          );
        }
    else{
    return (
        <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Link href='/'>
                             <img 
                                  className=" borderrr" 
                                  src="images/Case Diary Logo-200x200.png" 
                                  alt="logo" 
                                
                                  style={{width: '80px'}}
                             /> 
                             </Link>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form onSubmit={handleLogin} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Or Phone"
            name="email"
            autoComplete="email"
            autoFocus
          />
          
        
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#/login" variant="body2">
                Login
              </Link>
            </Grid>
        
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    )
    }
}
export { ForgetPassword };