import React, {useState, useEffect} from 'react'
import { Redirect } from "react-router"
import axios from 'axios';
import { BASE } from "../actions";
import {LOCAL_STORAGE_DATA_KEYNAME} from "../actions/types"
import {Container,Button,CssBaseline,Avatar,
    Typography,TextField,Grid,Link,Checkbox,Box,
    FormControlLabel} from '@material-ui/core';
    import { makeStyles } from '@material-ui/core/styles';
    import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
    import { setCurrentUser } from '../actions';
    import setAuthorizationData from '../utils/setAuthorizationData';
    import store from '../config/store';
    const useStyles = makeStyles((theme) => ({
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    }));
        
    function Copyright() {
      return (
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}{new Date().getFullYear()}
          {'.'}
          <Link color="inherit" href="https://fountainitservice.com">
            Fountain Software & IT Services
          </Link>{' '}
          
        </Typography>
      );
    }
    
 const Login = () => {
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(null);
    const props = { backgroundColor: 'black', color: 'white' };
      
    const classes = useStyles(props);
    const handleLogin = (e) => {
     
      setLoad(true);
      e.preventDefault();
      
      //ValidityState(e.target.value)

      const { history } = e;
     
      const data = new FormData(e.target);
      console.log(data.values());
      const api = axios.create({
        baseURL: BASE
      });
      api.post("/login", data)
      
        .then((res) => {
          //console.log('llll');
          setLoad(false);
          localStorage.setItem(LOCAL_STORAGE_DATA_KEYNAME, JSON.stringify(res.data.success.token));
          const userData = JSON.stringify(res.data); // Soooooo vital...

          /*eslint-disable*/
          localStorage.setItem('userData', userData);
         
          /*eslint-enable*/
          setAuthorizationData(JSON.parse(userData));
         // resolve(response);
         store.dispatch(setCurrentUser(userData))
          //history.push('#/dashboard');
         window.location.href = '#/dashboard';
        })
        .catch((error) => {
          setLoad(false);
          setError(error.response.data.loginmsg);
        });
    };
    
    const handleEmailChange=(e) =>{
      
        //console.log(e.target.value);
    }
    const handlePasswordChange=(e) =>{
      
    } 
    const ValidityState=(values)=>{
      let errors = {};
  
      //if (!values.username.trim()) {
       // errors.username = 'Username required';
      //}
      // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
      //   errors.name = 'Enter a valid name';
      // }
    
      if (!values.email) {
        errors.email = 'Email required';
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
      }
      //if (!values.password) {
     //   errors.password = 'Password is required';
      //} else if (values.password.length < 6) {
     //   errors.password = 'Password needs to be 6 characters or more';
     // }
      setError(errors);
    } 
    return (
        <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Typography component="h1" variant="h4">
      <Link href='/'>
                             <img 
                                  className=" borderrr" 
                                  src="images/Case Diary Logo-200x200.png" 
                                  alt="logo" 
                                
                                  style={{width: '80px'}}
                             /> 
                             </Link>
          
          </Typography>
        
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <p className="text-success">{load==true?'Submitting...':''}</p>
        <form onSubmit={handleLogin} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address Or Phone Number"
            name="email"
            autoComplete="email"
            onChange={handleEmailChange}
            autoFocus
            
            
          />
          <div className="text-danger">{error}</div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={handlePasswordChange}
            autoComplete="password"
            
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#/registration" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    )
}

export {Login,setCurrentUser};