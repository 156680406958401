import React, {useState, useEffect} from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { BASE } from "../../actions";
import { Redirect } from 'react-router';
import AppBarMain from '../shared/AppBarMain';
import DrawerMenu from '../shared/DrawerMenu';
import BkashButton from 'react-bkash';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}{new Date().getFullYear()}
      {'.'}
      <Link color="inherit" href="https://fountainitservice.com">
        Fountain Software & IT Services
      </Link>{' '}
      
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
root: {
  display: 'flex',
},
toolbar: {
  paddingRight: 24, // keep right padding when drawer closed
},
toolbarIcon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  ...theme.mixins.toolbar,
},
appBar: {
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
},
appBarShift: {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
menuButton: {
  marginRight: 36,
},
menuButtonHidden: {
  display: 'none',
},
title: {
  flexGrow: 1,
},
drawerPaper: {
  position: 'relative',
  whiteSpace: 'nowrap',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
drawerPaperClose: {
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: theme.spacing(7),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9),
  },
},
appBarSpacer: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
},
container: {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
},
paper: {
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
},
fixedHeight: {
  height: 240,
},
}));

function myCallBack(success, paymentData) { //success true or false
	if(success){
		/*
		* do something with `paymentData`
		*/
    
	} else {
		alert('payment failed');
	}
	window.location.reload();
} 

export default function PromoApply(props){
  var today = new Date(),
  //date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
   crr_date =today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const [redirect, setredirect] = useState(false);
  const classes = useStyles();
  const id = props.match.params.id;
  const [open, setOpen] = React.useState(true);
  const [isPaymentType, setPaymentType] = useState(false);
  const [packages, setpackages] = useState([]);
  const [subPackage, setSubPackage] = useState({});
  
  useEffect(() => {
    
    
  }, []);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  


  const handleSubmit = (e) => {
      
    e.preventDefault();
  
    const data = new FormData(e.target);
    //const case_type_id = data.get('id');
    //const name = data.get('court_no');
    
    const api = axios.create({
      baseURL: BASE
    });
     api.post("/promo-code-applies", data)
      .then((res) => {
       alert(res.data);
       window.location.reload(false); 
       //setredirect(true);
      })
      .catch((error) => {
        console.log(error);
      });
    
  }
  const options = {
    method: 'POST',
    headers: {Accept: 'application/json', 'Content-Type': 'application/json'}
  };
  
  
    
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  if (redirect) {
    return (<Redirect to={'/promocode-apply'} />); 
  }
    return (
        <div className={classes.root}>
         <CssBaseline /> 
        <AppBarMain pageName="Promocode Apply" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
          <DrawerMenu />
        <main className={classes.content}>
      <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ height: 400, width: '100%' }}>
            <h4 style={{ background:'#ddd',padding:'10px'}}>Promocode Apply </h4>
            
            <div className="col-md-8 mx-auto py-3">
            <form onSubmit={handleSubmit}  className={classes.form} noValidate>
            <div className="form-group row">
                <label for="name" className="col-md-5 font-weight-bold">Promocode:</label>
                <div className="col-md-7">
                <input type ="hidden" id="user_id" name="user_id" value={JSON.parse(localStorage.getItem('userData')).user.id} /> 
                <input type="text" name="promocode" className="form-control" placeholder="Apply promocode" />
                </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-5"></div>
                    <div className="col-md-7">
                    <input type="submit" value="Apply" className="btn btn-primary btn-sm" />
                    </div>
                    
            </div>
            
            </form>
            </div>
         </div>
         </Container>
        
         </main>
        
        </div>
    )
}
