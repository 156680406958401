import getUserDataFromLS from './getUserData';

export function hasRole(rolenames) {
    console.log( getUserDataFromLS());
    const savedRoleNames = [];
    const savedRoles = getUserDataFromLS() === null ? [] : getUserDataFromLS().role;
    savedRoles.forEach(element => { savedRoleNames.push(element.name); });
    //console.log('savedRoleNames  : ', savedRoleNames, '\nrolenames : ', rolenames);
    for (let i = 0; i < rolenames.length; i++) {
        if (savedRoleNames.includes(rolenames[i])) {
            return true;
        }
      }
     return false;
}