import React, {useState, useEffect} from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import { BASE } from "../../actions";
import {LOCAL_STORAGE_DATA_KEYNAME} from "../../actions/types"
import {Modal} from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import 'react-phone-input-2/lib/material.css'
import PhoneInputNew from 'react-phone-input-2'
function VerificationModal(props) {
  
  const classes = useStyles();
  const phone_no=props.mobile_no.phone_number;
  const user_name=props.user_name.user_name;
  const user_password=props.user_password.user_password;
  console.log("usernameeeeeee")
  console.log(user_name)
  console.log(user_password)
  const handleRegister = (e) => {
    console.log("xxxxxxxxvv")
    console.log(phone_no)
    //setLoad(true);
    e.preventDefault();
    
    const data = new FormData(e.target);
    console.log("dataaaaaaaaaaaaaaaaaa")
  const verif_code=data.get('vcode');
  console.log("verif_code")
  console.log(verif_code)
    const api = axios.create({
      baseURL: BASE
    });
    api.post("/user/mobile/verify", {mobileno:phone_no,verif_code:verif_code,username:user_name,userpassword:user_password})
    .then((res)=>{
      if(res.data.message.indexOf("success") !== -1)
      {
        window.location.href = '#/login';
      }
      else if(res.data.message.indexOf("expired") !== -1)
      {
        alert("OTP Expired")
       
      }
      else
      {
        alert("Verification failed, Wrong OTP");
      }
      console.log("res");
      console.log(res);
    })
      // .then((res) => {
      // //   setLoad(false);
      // //   localStorage.setItem(LOCAL_STORAGE_DATA_KEYNAME, JSON.stringify(res.data.success.token));
      // //  //history.push('#/dashboard');
      
      // })
      .catch(res => {
        // setLoad(false); 
        // setError(res.response.data.name);
        // setEmailError(res.response.data.email);
        // setPasswordError(res.response.data.password);
        // setRePasswordError(res.response.data.confirm_password);
        
    })
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Verify Mobile No
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>SMS has been sent to {phone_no}</h4>
        <p>
          <div>
            
          <form onSubmit={handleRegister}  className={classes.form} ValidatorForm>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="vcode"
                name="vcode"
                variant="outlined"
                
                fullWidth
                id="vcode"
                label="OTP Code"
                autoFocus
                required
              />
               {/* {(emailerror)?<p className="text-danger">{error}</p>:''} */}
            </Grid>
            
            
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Verify
          </Button>
          <Grid hidden container justify="flex-end">
            <Grid item>
              <Link href="{handleLogin}" variant="body2">
                Don't have the code? Resend
              </Link>
            </Grid>
          </Grid>
        </form>
      
          </div>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}





function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}{new Date().getFullYear()}
        {'.'}
        <Link color="inherit" href="https://fountainitservice.com">
          Fountain Software & IT Services
        </Link>{' '}
        
      </Typography>
    );
  }

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {

  const [modalShow, setModalShow] = useState(false);
  const [value, setValue] = useState()
  const [error, setError] = useState(null);
  const [nameerror, setNameError] = useState(null);

  const [emailerror, setEmailError] = useState(null);
  const [passworderror, setPasswordError] = useState(null);
  const [repassworderror, setRePasswordError] = useState(null);

  const [load, setLoad] = useState(null);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [is_check, setIscheck] = useState(0);
  const classes = useStyles();
  const handleLogin = (e) => {

    
    e.preventDefault();
    const { history } = e;

const data = new FormData(e.target);
   
  const phone_number=data.get('phone');
  const mobile_no = phone_number.replace(/ /g, '')
  const user_name=data.get('name');
  const user_password=data.get('password');
  const confirm_password=data.get('confirm_password');
  setEmailError(null)
  
    var len=mobile_no.length
    if(len<14)
    {
      setEmailError("Input Full Mobile no with country code")
      return;
    } 
 
  
  var len1=user_password.length
  if(len1<6)
  {
    setPasswordError("Input 6-12 characters")
    return;
  }
  
  
  var len2=confirm_password.length
  if(len2<6)
  {
    setRePasswordError("Input 6-12 characters")
    return;
  }
  
  if (typeof user_password !== "undefined" && typeof confirm_password !== "undefined")
   {
  if(user_password!=confirm_password)
  {
    setRePasswordError("Password does not match!")
    return;
  }
  
 
}
setLoad(true);
  setUserName({user_name})
  setUserPassword({user_password})
  setPhone({phone_number})
  console.log("datttttttt")
  console.log(data)
  
    const api = axios.create({
      baseURL: BASE
    });
    api.post("/user/generateOTP", {phone:mobile_no})
    .then((res)=>{
      setLoad(false);
      if(res.data.message.indexOf("success") !== -1)
      {
        setModalShow(true)
      }
      // else if(res.data.message.indexOf("expired") !== -1)
      // {
      //   alert("OTP Expired")
       
      // }
      else 
      {
        if(res.data.status_code=='200'){
        alert(res.data.message);
        }else{
          alert('Sending OTP failed');
        }
      }
     
    })
      // .then((res) => {
      //   setLoad(false);
      //   localStorage.setItem(LOCAL_STORAGE_DATA_KEYNAME, JSON.stringify(res.data.success.token));
      //  //history.push('#/dashboard');
      //  window.location.href = '#/login';
      // })
      .catch(res => {
        setLoad(false); 
         //setError("OTP sending failed");
        setEmailError(res.response.data.mobileno);
        //setEmailError(res.response.data.phone);
         setPasswordError(res.response.data.password);
        setRePasswordError(res.response.data.confirm_password);

        
    })
  };
  const onCheckboxClick=(e)=>{
    if(e.target.checked==true){
      setIscheck(1);
    }else{
      setIscheck(0);
    }
  }
  const handleChange = (event) => {
   
setPasswordError(null);
setRePasswordError(null);

  setEmailError(null)

  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {(error)?<p className="text-danger">{error}</p>:''}
      <VerificationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        mobile_no={phone}
        user_name={userName}
        user_password={userPassword}
      />
      <div className={classes.paper}>
      <Link href='/'>
                             <img 
                                  className=" borderrr" 
                                  src="images/Case Diary Logo-200x200.png" 
                                  alt="logo" 
                                
                                  style={{width: '80px'}}
                             /> 
                             </Link>
       
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <p className="text-success">{load==true?'Submitting...':''}</p>
        <form onSubmit={handleLogin}  className={classes.form} ValidatorForm>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                
                fullWidth
                id="name"
                label="Name"
                autoFocus
                required
              />
               {(nameerror)?<p className="text-danger">{error}</p>:''}
            </Grid>
            
           
            
            <Grid item xs={12}>
     
            <PhoneInputNew
            country={'bd'}
            placeholder={' +8801XXXXXXXXX '}
            specialLabel={' Mobile No *'}
              onChange={handleChange}
            inputProps={{
              name: 'phone',
              required: true,
            
              id:"phone_number",
              countryCodeEditable:false
              
            }}
  
  
              
              
/> 
              {(emailerror)?<p className="text-danger">{emailerror}</p>:''}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={handleChange}
                autoComplete="current-password"
                required
              />
              {(passworderror)?<p className="text-danger">{passworderror}</p>:''}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirm_password"
                label="Confirm Password"
                type="password"
                id="cofirm_password"
                onChange={handleChange}
                autoComplete="cofirm-password"
                required
              />
               {(repassworderror)?<p className="text-danger">{repassworderror}</p>:''}
            </Grid>
            <Grid item xs={12}>
            
            <div className=""><label><input type="checkbox" required onClick={onCheckboxClick} /> I agree with <a href='#/terms'>Terms & Conditions</a></label></div>
               {(repassworderror)?<p className="text-danger">{repassworderror}</p>:''}
            </Grid>
          </Grid>
          {is_check==1?<div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
        </div>:<div className="btn d-block disabled"> Sign Up</div>}
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="#/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}