import React, { Component } from 'react';
import axios from 'axios';
import { BASE } from "../../actions";
import AsyncSelect from 'react-select/async';
import { hasRole } from '../../utils/hasRoleCheck';
import Select from 'react-select';
import {
  ROLE_ADMIN,
  ROLE_LAWYER,

} from '../../components/common/ConstantValues';
export default class ReportHistoryList extends Component {
    state = {
        cases: [],
        checked: '',
        caseIdNos: [],
        selectedIDNO:null,
      }
      handleChangeCase = e =>{
        if(e){
          const api = axios.create({
            baseURL: BASE
          });
          this.setState({ selectedIDNO: e.id });
          if(hasRole([ROLE_ADMIN])){
            api.get('/case-report-history?search='+e.case_id_no)
              .then(res => {
                const cases = res.data;
                this.setState({ cases });
              })
              
            }
        }else{
          this.setState({cases:[]});
        }
        
      } 
    handleSearch(e){
        let value = e.target.value;
        let id = e.target.name;
        const api = axios.create({
          baseURL: BASE
        });
        if(hasRole([ROLE_ADMIN])){
        api.get('/case-report-history?search='+value)
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
          
        }else{
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          api.get('/cases/'+user_id+'/0')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }
      }
      componentDidMount() {
        const api = axios.create({
          baseURL: BASE
        });
        api.get('/all-cases-id-no')
        .then(res => {
          const caseIdNos = res.data;
          this.setState( {caseIdNos} );
        
        })
      
      } 
    render() {
        return (
            <div>
                 <div className="form-group col-md-4">
                  <label>Case ID NO</label>
                  <Select 
                  isClearable
                  options={this.state.caseIdNos}
                  onChange={this.handleChangeCase}
                  getOptionLabel={e => e.case_id_no}
                  getOptionValue={e => e.id}
                  value={this.selectedIDNO}
                  name="case_id_no"
                   placeholder={<span className="text-success">Search By Case ID NO</span>}
                
                />
                 </div>
                {/* <div className="">
                 <label>Search:</label> 
               <input type="text" className="form-control" onChange={this.handleSearch.bind(this)} name="search"></input>
                </div>*/}
            <table className="table table-responsive">
                    <thead>
                        <tr>
                            <th style={{width:"5%"}}>Case ID</th>
                            <th>Case No.</th>
                         
                            <th>Area</th>
                            <th>Type</th>
                            <th>Court</th>
                            <th>For</th>
                            <th>Next Date</th>
                            <th>Result</th>
                        
                            <th>Case Status</th>
                            <th>updated Date</th>
                           
                        </tr>
                    
                    </thead>
                    <tbody>
                    { this.state.cases.map((cases, index) =><tr>
                     <td>{cases.case_id_no}</td>
                     <td>{ cases.case_no}</td>
                    
                     <td>{cases.area_name}</td>
                     <td>{cases.case_type}</td>
                     <td>{cases.court_no}</td>
                
                     <td>{cases.case_for}</td>
                     <td>{cases.next_date}</td>
                     <td>{cases.result}</td>
                   
                     <td>{(cases.case_close)==1?<span className="text-danger">Closed</span>:<span className="text-success font-weight-bold">Open</span>}</td>
                     <td>{cases.created}</td>
                     </tr> )}
                </tbody>
                </table> 
            </div>
        )
    }
}
