
import React from 'react'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { logout } from '../../actions/auth_actions';
import Button from '@material-ui/core/Button';


export default function ProfileMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout=(event) =>{
        
    logout(); 
    
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
    return (
        <div>
             <Button aria-controls="simple-menu" color="inherit" aria-haspopup="true" onClick={handleClick}>
          {JSON.parse(localStorage.getItem('userData'))?JSON.parse(localStorage.getItem('userData')).user.name:''}  <i className="fa fa-angle-down"></i> 
</Button>
<Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
>

  <MenuItem onClick={handleClose}><a href="#/account-setting">Account Setting</a></MenuItem>
  <MenuItem onClick={handleClose}><a href="/" onClick={handleLogout}>Logout</a></MenuItem>
</Menu>
        </div>
    )
}

/*export default class ProfileMenu extends Component {
    //const [anchorEl, setAnchorEl] = React.useState(null);
    constructor(props) {
        super(props);
        this.state = {
          input: {},
          errors: {},
          setAnchorEl:0
        };
        this.handleLogout = this.handleLogout.bind(this); 
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
      }
      handleLogout() {
        
        logout(); 
        
      }
   handleClick(event){
       let setAnchor = this.state.setAnchorEl;
       console.log('ll');
    this.setState({setAnchor:true});
  };
   
  handleClose() {
    this.state.setAnchorEl(null);
  };
    render() {
    
        return (
            <div>
                <Button aria-controls="simple-menu" color="inherit" aria-haspopup="true" onClick={this.handleClick}>
          {JSON.parse(localStorage.getItem('userData')).user.name}  <i className="fa fa-angle-down"></i> {this.state.anchorEl}
</Button>
<Menu
  id="simple-menu"
  anchorEl={this.state.anchorEl}
  keepMounted
  open={Boolean(this.state.anchorEl)}
  onClose={this.handleClose}
>
  <MenuItem onClick={this.handleClose}><a href="#">Profile</a></MenuItem>
  <MenuItem onClick={this.handleClose}><a href="#">My account</a></MenuItem>
  <MenuItem onClick={this.handleClose}><a href="/" onClick={this.handleLogout}>Logout</a></MenuItem>
</Menu>
            </div>
        )
    }
}
*/
