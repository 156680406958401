import React, {useState, useEffect} from 'react'
import Navlist from '../../dashboard/Navlist';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { BASE } from "../../../actions";
import { Redirect } from 'react-router';
import ProfileMenu from "../../common/ProfileMenu";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}{new Date().getFullYear()}
      {'.'}
      <Link color="inherit" href="https://fountainitservice.com">
        Fountain Software & IT Services
      </Link>{' '}
      
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
root: {
  display: 'flex',
},
toolbar: {
  paddingRight: 24, // keep right padding when drawer closed
},
toolbarIcon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  ...theme.mixins.toolbar,
},
appBar: {
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
},
appBarShift: {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
menuButton: {
  marginRight: 36,
},
menuButtonHidden: {
  display: 'none',
},
title: {
  flexGrow: 1,
},
drawerPaper: {
  position: 'relative',
  whiteSpace: 'nowrap',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
drawerPaperClose: {
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: theme.spacing(7),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9),
  },
},
appBarSpacer: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
},
container: {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
},
paper: {
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
},
fixedHeight: {
  height: 240,
},
}));


  
export default function CourtNoAdd(){
  const [redirect, setredirect] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [errors, setErrors] = React.useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSubmit = (e) => {
      
    e.preventDefault();
  
    const data = new FormData(e.target);
    const case_type_id = data.get('id');
    const name = data.get('court_no');
  
    const api = axios.create({
      baseURL: BASE
    });
     api.post("/courtno", data)
      .then((res) => {
       alert('success');
       setredirect(true);
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data);
      });
    
  }
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  if (redirect) {
    return (<Redirect to={'/court-no'} />); 
  }
    return (
        <div className={classes.root}>
        <CssBaseline />
        
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        
          <Toolbar className={classes.toolbar}>
          <Grid item xs={12} md={11} lg={11}>   
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Case No. Add
            </Typography>
            </Grid>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
              <ProfileMenu />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
          <h3>
              Case Diary
          </h3>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <Navlist/>
         
        </Drawer>
        <main className={classes.content}>
      <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ height: 400, width: '100%' }}>
            <h4 style={{ background:'#ddd',padding:'10px'}}>Case No. Add</h4>
            <a href="#/court-no" className="btn btn-primary float-right">Back</a>
            <form onSubmit={handleSubmit}  className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="court_no"
                name="court_no"
                variant="outlined"
                required
                fullWidth
                id="court_no"
                label="Name"
                autoFocus
              />
              <span>{errors.court_no}</span>
            </Grid>
            </Grid>
            <Button
            type="submit"
            
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
            </form>
         </div>
         </Container>
        
         </main>
        
        </div>
    )
}
