import React, {useState, useEffect} from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { BASE } from "../../actions";
import { hasRole } from "../../utils/hasRoleCheck";
import AppBarMain from '../shared/AppBarMain';
import DrawerMenu from '../shared/DrawerMenu';
import { Redirect } from 'react-router';
import DatePicker from "react-datepicker";
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}{new Date().getFullYear()}
        {'.'}
        <Link color="inherit" href="https://fountainitservice.com">
          Fountain Software & IT Services
        </Link>{' '}
        
      </Typography>
    );
  }
  
  const drawerWidth = 240;
  
  const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  }));
 
export default function AddCase(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const [age,setAge] = React.useState('');
    const [case_type,setCaseType] = React.useState('');
    const [case_for,setCaseFor] = React.useState('');
    const [error, setError] = useState(null);
    const [casenoerror, setcasenoerror] = useState(null);
    const [areaerror, setarea] = useState(null);
    const [courtNo, setCourtNo] = useState(null);
    const [errorcaseNo, seterrorCaseNo] = useState(null);
    const [isCase, setIscase] = useState(1);
    const [nextdate,setNextdate] = React.useState('');
    const [prevdate,setprevdate] = React.useState('');
    const [load, setLoad] = useState(null);
    const handleCase = (e) => {
      setLoad(true);
      e.preventDefault();
      const data = new FormData(e.target);
      const area = data.get('area');
      const case_no = data.get('case_no');
      const case_id_no = data.get('case_id_no');
      const court_no = data.get('court_no');
      const type = data.get('type');
      const case_for = data.get('for');
      const next_date = data.get('next_date');
      const api = axios.create({
        baseURL: BASE
      });
     
     if(area!='' && case_no!='' && case_id_no!='' && court_no!='' && type!='' && case_for!=''&& next_date){
      
      api.post("/cases", data)
        .then((res) => {
          setredirect(true);
          setLoad(false);
          //window.location.href="/#/lawyer-wise-active-case";
          //window.redirect.href='#/lawyer-wise-active-case';
          alert('Successfully Added!!!');
      
        })
        .catch(res => {
          setLoad(false); 
          alert('Sorry try again!!');
          setError(res.response.data.case_id_no);
          setcasenoerror(res.response.data.case_no);
          setarea(res.response.data.area);
          setCourtNo(res.response.data.court_no);
          
      })
    
    }else{
      alert('PLease fillup all required(*) fields.');
    }
    };
  const handleChange = (e) => {
    const id=document.getElementById('area').value;
    
    
    //case-id-no
    const case_id = e.target.value;
    api.post("/case-check", {'case_no':case_id,'area':id})
      .then((res) => {
          if(res.data==1){
            seterrorCaseNo("Case no. already exist!!!");
            setIscase(0);
          }else{
            seterrorCaseNo();
            setIscase(1);
          }
      });
    api.get('/case-id-no?area='+id+'&case_id='+case_id)
    .then(res => {
      const cases = res.data;
      setCaseID( cases );
     
  });
}
    const handleDrawerClose = () => {
      setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [post, setPost] = useState([]);
    const [types, setType] = useState([]);
    const [casefors, setCase] = useState([]);
    const [courtno, setCourtno] = useState([]);
    const [areas, setAreas] = useState([]);
    const [case_id_no, setCaseID] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [redirect, setredirect] = useState(false);
 // const id = props.match.params.id;
 
  const api = axios.create({
    baseURL: BASE
  });

  useEffect(() => {
    api.get('/all-lawyers')
    .then(res => {
      const cases = res.data;
      setPost( cases );
     
    })
    api.get('/case-type')
    .then(res => {
      const types = res.data;
      setType( types );
  
    })
    api.get('/case-for')
    .then(res => {
      const casefors = res.data;
      setCase( casefors );
     })
    api.get('/courtno')
    .then(res => {
      const courtfors = res.data;
      setCourtno( courtfors );
     
    })
    api.get('/area')
    .then(res => {
      const areas = res.data;
      setAreas( areas );
     
    })
  }, []);

  if (redirect) {
    return (<Redirect to={'/lawyer-wise-active-case'} />); 
  }
   // let colors = post;
   
      return (
          <div className={classes.root}>
        
          <CssBaseline />
          <AppBarMain counter={props.counter} pageName="Add Case" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
          <DrawerMenu />
         <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <div style={{ height: 400, width: '100%' }}>
              <h4 style={{ background:'#ddd',padding:'10px'}}>Add New Case</h4>
              <p>Please Enter Case Details</p>
              
              <form onSubmit={handleCase} className={classes.form} noValidate style={{ width:'400px',margin:'0 auto'}}>
              
    {(hasRole(['LAWYER']))&&
    <div>
    <input type="hidden" name="user_id" value={JSON.parse(localStorage.getItem('userData')).user.id} />
    </div>
}
    <div className="form-group">
              <label for="area_id">Area<span className="text-danger">*</span></label>
              <select className="form-control" name="area" id="area">
                    <option value=''>Select Area</option>
                    { areas.map(area =>
                      <option value={area.id}>
                         {area.area_name}
                        </option>)}
                        </select>
                        {areaerror}
                        </div>
                        <div className="form-group">
              <label for="case_no">Case No<span className="text-danger">*</span></label>
         <input type="text" 
         className="form-control"
          name="case_no"
          placeholder="Enter Case No" onChange={handleChange} />
          {casenoerror}
          
        </div>
        <div className="form-group">
              <label for="case_id_no">Case ID<span className="text-danger">*</span></label>
              <input type="text"
              id="case_id_no"
               className="form-control"
                name="case_id_no"
                placeholder="Enter Case ID"
                value={case_id_no}
                readOnly />
                {error}
                <span className="text-danger">{errorcaseNo}</span>
        </div>

        <div className="form-group">
              <label for="case_no">Court No<span className="text-danger">*</span></label>
            <select className="form-control" id="court_no" name="court_no">
                        <option value="">Select Court</option>
                        { courtno.map(courtno =>
                          <option value={courtno.id}>
                            {courtno.court_no}
                          </option>)}
            </select>
            {courtNo}
            
        </div>
        <div className="form-group">
              <label for="case_type">Type<span className="text-danger">*</span></label>
            <select className="form-control" name="type">
                        <option value="">Select Type</option>
                        { types.map(type =>
                          <option value={type.id}>
                            {type.name}
                          </option>)}
            </select>
        </div>
        <div className="form-group">
              <label for="case_for">For<span className="text-danger">*</span></label>
              <select className="form-control" id="case_for" name="for">
                    <option value="">Select For</option>
                    { casefors.map(casefor =>
                      <option value={casefor.id}>
                         {casefor.name}
                        </option>)}
              </select>
        </div>
      <div className="form-group">
              <label for="advocate_one">Advocate 1</label>
              <input type="text"
               className="form-control"
                name="advocate_one"
                placeholder="Enter Advocate One"
                 />
        </div>
        <div className="form-group">
              <label for="advocate_two">Advocate 2</label>
              <input type="text"
               className="form-control"
                name="advocate_two"
                placeholder="Enter Advocate Two"
                 />
        </div>
        <div className="form-group">
              <label for="party_one">Party One</label>
              <input id="party_one"
            className="form-control"
            name="party_one"
            placeholder="Enter Party One"
            />
        </div>
        <div className="form-group">
              <label for="party_two">Party Two</label>
              <input id="party_two"
            className="form-control"
            name="party_two"
            placeholder="Enter Party Two"
            />
        </div>
        <div className="form-group">
              <label for="previous_case_date">Previous Case Date</label>

            <div>
                <DatePicker style={{width:'100%'}} className="search-field form-control"
                            id="previous_case_date"

                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            selected={prevdate}
                            name="previous_date"
                            onChange={(date) => setprevdate(date)}
                />
            </div>
        </div>
        <div className="form-group">
              <label for="next_case_date">Next Case Date<span className="text-danger">*</span></label>
            <div>
            <DatePicker style={{width:'100%'}} className="search-field form-control"
                        id="next_case_date"

                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/mm/yyyy"
                        selected={nextdate}
                        name="next_date"
                        onChange={(date) => setNextdate(date)}
            />
            </div>
        </div>
        <div className="form-group">
              <label for="result">Result</label>
        <input id="result"
         className="form-control"
          name="result"
           placeholder="Enter Result"
            />
        </div>
        
        {(hasRole(['DEVELOPER', 'ADMIN']))&&
        
              <div className="form-group">
                <input type ="hidden" name="entry_by" value={JSON.parse(localStorage.getItem('userData')).user.id} />
              <label for="user_id">Lawyer</label>
              
              <select className="form-control" id="user_id" name="user_id">
                    <option value="">Select Lawyer</option>
                    { post.map(cases =>
                      <option value={cases.id}>
                         {cases.name}
                        </option>)}
                        </select>
                        </div>
    }
    {
      (isCase)?
    
          <Button
            type="submit"
            style={{ margin: 8 }}
            variant="contained"
            color="primary"
            className={classes.submit}
            
          >
            Submit
          </Button>:<Button className="btn disabled">Submit</Button>}
          </form>
           </div>
           </Container>
          
           </main>
          
          </div>
      )
}
