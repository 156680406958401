import axios from 'axios';

export default function setAuthorizationData(userData) {
    if (userData) {
        //console.log('Ins setAuthorizationData userData ==================== ', userData.success.token);
        const token = userData.success.token; 
        axios.defaults.headers.common['x-access-token'] = `${token}`;
        //console.log('axios.defaults.headers ================ ', axios.defaults.headers.common);
        //axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; //
    } else {
        console.log('delete authorization header token...');
        delete axios.defaults.headers.common['x-access-token']; 
    }
}