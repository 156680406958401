import React, {useState, useEffect} from 'react'
import Navlist from '../dashboard/Navlist';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { BASE } from "../../actions";
import { hasRole } from "../../utils/hasRoleCheck";
import ProfileMenu from "../common/ProfileMenu";
import DropdownList from 'react-widgets/lib/DropdownList';
import { Redirect } from 'react-router';
import DatePicker from "react-datepicker";
import moment from "moment";


function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}{new Date().getFullYear()}
        {'.'}
        <Link color="inherit" href="https://fountainitservice.com">
          Fountain Software & IT Services
        </Link>{' '}
        
      </Typography>
    );
  }
  
  const drawerWidth = 240;
  
  const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  }));
 
export default function EditCase(props) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const [area,setState] = React.useState('');
    const [case_id,setSelectedCaseID] = React.useState('');
    const [selectedCourt,setSelectedCourt] = React.useState('');
    const [selectedType,setSelectedType] = React.useState('');
    const [selectedFor,setSelectedFor] = React.useState('');
    const [selectedLawyer,setSelectedLawyer] = React.useState('');
    const [case_type,setCaseType] = React.useState('');
    const [case_for,setCaseFor] = React.useState('');
    const [error, setError] = useState(null);

    const [redirect, setredirect] = useState(false);
    const [emailerror, setEmailError] = useState(null);
    const [passworderror, setPasswordError] = useState(null);
    const [repassworderror, setRePasswordError] = useState(null);
    const [nextdate,setNextdate] = React.useState('');
    const [prevdate,setprevdate] = React.useState('');
    const [load, setLoad] = useState(null);
    const handleCase = (e) => {
      setLoad(true);
      e.preventDefault();
      const { history } = e;
     
      const data = new FormData(e.target);
    
      const api = axios.create({
        baseURL: BASE
      });
      api.post("/cases", data)
        .then((res) => {
          setLoad(false);
          alert('success');
          window.location.reload(false);
        })
        .catch(res => {
          setLoad(false); 
          
          setError(res.response.data.name);
          setEmailError(res.response.data.email);
          setPasswordError(res.response.data.password);
          setRePasswordError(res.response.data.confirm_password);
          
      })
    };
    const handleSubmit = (e) => {
      
      e.preventDefault();
    
      const data = new FormData(e.target);
      const id = data.get('id');
      const case_no = data.get('case_no');
      const court_no = data.get('court_no');
      const case_id_no = data.get('case_id_no');
      const area = data.get('area');
      const type = data.get('type');
      const case_for = data.get('for');
      const advocate_one = data.get('advocate_one');
      const advocate_two = data.get('advocate_two');
      const party_one = data.get('party_one');
      const party_two = data.get('party_two');

      const previous_date = data.get('previous_date');
      const next_date = data.get('next_date');
      const result = data.get('result');
      const api = axios.create({
        baseURL: BASE
      });
       api.put("/cases/edit/"+id, {'case_no':case_no,'case_id_no':case_id_no,'court_no':court_no,'area':area,
       'type':type,
       'for':case_for,
       'advocate_one':advocate_one,'advocate_two':advocate_two,'party_one':party_one,
       'party_two':party_two,'previous_date':previous_date,
       'next_date':next_date,'result':result})
        .then((res) => {
         alert('success');
         setredirect(true);
        // history.push('/login');
         //window.location.href('/active-case');
        })
        .catch((error) => {
          console.log(error);
        });
      
    }
  const handleChange = (e) => {
    const id=document.getElementById('area').value;
    //case-id-no
    setState(id);
    const case_id = e.target.value;
    //setCaseID(id+ );
    api.get('/case-id-no?area='+id+'&case_id='+case_id)
    .then(res => {
      const cases = res.data;
      setSelectedCaseID( cases );

     
  });
}
const handChange = (e) => {
  //console.log();
  setState(e.target.value );
};
const handleChangeType = (e) => {
  //console.log();
  setSelectedType(e.target.value );
};
const handleChangeFor = (e) => {
  //console.log();
  setSelectedFor(e.target.value );
};
const handleChangeCourt = (e) => {
  //console.log();
  setSelectedCourt(e.target.value );
};
    const handleDrawerClose = () => {
      setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [post, setPost] = useState([]);
    const [caseOne,setcases] = useState([]);
    const [types, setType] = useState([]);
    const [casefors, setCase] = useState([]);
    const [courtno, setCourtno] = useState([]);
    const [areas, setAreas] = useState([]);
    const [case_id_no, setCaseID] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
  const id = props.match.params.id;
 
  const api = axios.create({
    baseURL: BASE
  });

  useEffect(() => {
    api.get('/cases-edit/'+id)
    .then(res => {
      const cases = res.data;
      setcases( cases );
      setState(cases.area);
      setSelectedCaseID(cases.case_id_no);
      setSelectedFor(cases.for);
      setSelectedType(cases.type);
      setSelectedCourt(cases.court_no);
      setNextdate(new Date(cases.next_date))
      setprevdate(new Date(cases.previous_date))
      setSelectedLawyer();
    })
    api.get('/all-lawyers')
    .then(res => {
      const cases = res.data;
      setPost( cases );
     
    })
    api.get('/case-type')
    .then(res => {
      const types = res.data;
      setType( types );
  
    })
    api.get('/case-for')
    .then(res => {
      const casefors = res.data;
      setCase( casefors );
     })
    api.get('/courtno')
    .then(res => {
      const courtfors = res.data;
      setCourtno( courtfors );
     
    })
    api.get('/area')
    .then(res => {
      const areas = res.data;
      setAreas( areas );
     
    })
  }, []);
  

   // let colors = post;
   if (redirect) {
    return (<Redirect to={'/active-case'} />); 
  }

      return (

          <div className={classes.root}>
          <CssBaseline />
          
          <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          
            <Toolbar className={classes.toolbar}>
            <Grid item xs={12} md={11} lg={11}>   
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Edit Case
              </Typography>
              </Grid>
              <IconButton color="inherit">
                <Badge badgeContent={4} color="secondary">
                  <NotificationsIcon />
                </Badge>
                
              </IconButton>
              <ProfileMenu />
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
            <h3>
                Case Diary
            </h3>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <Navlist/>
           
          </Drawer>
          <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <div style={{ height: 400, width: '100%' }}>
            <h4 style={{ background:'#ddd',padding:'10px'}}>Edit Case</h4>
              <a className="btn btn-primary btn-sm" href="#/active-case">Back</a>
              <p>Please Enter Case Details</p>
              <form onSubmit={handleSubmit} className={classes.form} noValidate style={{ width:'400px',margin:'0 auto'}}>
              <input type="hidden" name="id" value={caseOne.id} />
    {(hasRole(['LAWYER']))&&
    <div>
    <input type="hidden" name="user_id" value={JSON.parse(localStorage.getItem('userData')).user.id} />
    </div>
}
    <div className="form-group">
              <label for="area_id">Area<span className="text-danger">*</span></label>
              <select className="form-control" onChange={handChange} value= { area } name="area" id="area">
                    <option>Select Area</option>
                   
                    { areas.map(area =>
                      <option value={area.id} >
                         {area.area_name}
                        </option>)}
                        </select>
                        </div>
                        <div className="form-group">
              <label for="case_no">Case No</label>
         <input type="text" 
         className="form-control"
          name="case_no"
          id="case_no"
          defaultValue= { caseOne.case_no }
          placeholder="Enter Case No" onChange={handleChange} />
        </div>
        <div className="form-group">
              <label for="case_id_no">Case ID<span className="text-danger">*</span></label>
              <input type="text"
              id="case_id_no"
               className="form-control"
                name="case_id_no"
                placeholder="Enter Case ID"
               
                value={case_id}
                readOnly />
        </div>
        <div className="form-group">
              <label for="case_no">Court No<span className="text-danger">*</span></label>
            <select className="form-control mx-2" onChange={handleChangeCourt} value={selectedCourt} id="court_no" name="court_no">
                        <option value="">Select Court</option>
                        { courtno.map(courtno =>
                          <option value={courtno.id}>
                            {courtno.court_no}
                          </option>)}
            </select>
        </div>
        <div className="form-group">
              <label for="case_type">Type<span className="text-danger">*</span></label>
            <select className="form-control mx-2" onChange={handleChangeType} value={selectedType} name="type">
                        <option value="">Select Type</option>
                        { types.map(type =>
                          <option value={type.id}>
                            {type.name}
                          </option>)}
            </select>
        </div>
        <div className="form-group">
              <label for="case_for">For<span className="text-danger">*</span></label>
              <select className="form-control mx-2" onChange={handleChangeFor} value={selectedFor} id="case_for" name="for">
                    <option value="">Select For</option>
                    { casefors.map(casefor =>
                      <option value={casefor.id}>
                         {casefor.name}
                        </option>)}
              </select>
        </div>
      <div className="form-group">
              <label for="advocate_one">Advocate 1</label>
              <input type="text"
               className="form-control"
                name="advocate_one"
                placeholder="Enter Advocate One"
                defaultValue= { caseOne.advocate_one }
                 />
        </div>
        <div className="form-group">
              <label for="advocate_two">Advocate 2</label>
              <input type="text"
               className="form-control"
                name="advocate_two"
                placeholder="Enter Advocate Two"
                defaultValue= { caseOne.advocate_two }
                 />
        </div>
        <div className="form-group">
              <label for="party_one">Party One</label>
              <input id="party_one"
            className="form-control"
            name="party_one"
            placeholder="Enter Party One"
            defaultValue= { caseOne.party_one }
            />
        </div>
        <div className="form-group">
              <label for="party_two">Party Two</label>
              <input id="party_two"
            className="form-control"
            name="party_two"
            placeholder="Enter Party Two"
            defaultValue= { caseOne.party_two }
            />

        </div>
        <div className="form-group">
              <label for="previous_case_date">Previous Case Date</label>

            <div>
                <DatePicker style={{width:'100%'}} className="search-field form-control"
                            id="previous_case_date"
                            /*caseOne.next_date? '12/02/2022':*/
                            dateFormat="dd/MM/yyyy"
                            //dateFormat="DD MMM YYYY"
                            placeholderText="dd/mm/yyyy"
                            selected={prevdate}
                            name="previous_date"
                            onChange={(date) => setprevdate(date)}
                />
            </div>
        </div>
        <div className="form-group">
              <label for="next_case_date">Next Case Date<span className="text-danger">*</span></label>

            <div>
                <DatePicker style={{width:'100%'}} className="search-field form-control"
                            id="next_case_date"
                    /*caseOne.next_date? '12/02/2022':*/
                            dateFormat="dd/MM/yyyy"
                    //dateFormat="DD MMM YYYY"
                            placeholderText="dd/mm/yyyy"
                            selected={nextdate}
                            name="next_date"
                            onChange={(date) => setNextdate(date)}
                />
            </div>
        </div>
        <div className="form-group">
              <label for="result">Result</label>
        <input id="result"
         className="form-control"
          name="result"
          defaultValue= { caseOne.result }
           placeholder="Enter Result"
            />
        </div>
        
          <Button
            type="submit"
            style={{ margin: 8 }}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update
          </Button>
          </form>
           </div>
           </Container>
          
           </main>
          
          </div>
      )
}
