
import React,{setState,useRef, useEffect } from 'react'
import {  messaging } from '../../firebase';
import axios from 'axios';
import { MDBBadge, MDBContainer } from "mdbreact";

import {BASE} from '../../actions';
//import { ChatList,MessageBox,MessageList,Input,Button} from 'react-chat-elements'
//import 'react-chat-elements/dist/main.css';
import ReactRoundedImage from "react-rounded-image";
//import './chat.scss'
import './message.css'
import MyPhoto from "../../assets/images/dummy-profile.png";
import { Badge } from 'material-ui';
import './script.js';


const ChatDiv = (props) => {
  const chatid=props.chatid;
  const time=props.time
  if (chatid==1)
  {
  return  <div class="message-box-holder">
    <div class="message-time-me">
      {props.time}
    </div>
    <div class="message-box">
     {props.msg}
    </div>
    
  </div>
  }
  else
  {
    return  <div class="message-box-holder">
    {/* <div class="message-sender">
      <a href="#">Chris Jerrico</a>
     </div> */}
    <div class="message-box message-partner">
     {props.msg}
    </div>
    <div class="message-time-partner">
      {props.time}
    </div>
  </div>
  }
}

class Chatwindow extends React.Component {
  


    constructor(props) {
        super(props);
        this.divRef = React.createRef();
        const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        this.state = {
            lawyer_name:(props.lawyer_name==""||props.lawyer_name==null)?"Lawyer Name":props.lawyer_name,
            lawyer_id:(props.lawyer_id=="0" ||props.lawyer_id==null) ?"":"("+props.lawyer_id+")",
            ticket_no:(props.ticket_no==""||props.ticket_no==null)?"Issue No":props.ticket_no,
            ticket_id:props.ticket_id==null?0:props.ticket_id,
            lawyer_img:props.img_url==null?"":props.img_url,
           issue_status:props.issue_status==null?"Closed":props.issue_status,
            messages: [],
            currentMessage:'',
            remoteMessage:'',
            divs:[],
            chatid:1,
            chattime:(new Date()).toLocaleDateString('en-GB'),
            chatMsg:''
            //...
          };
          new Promise((resolve) => {
          messaging.onMessage((payload) => {
            console.log('cccccccc')
            resolve(payload);
            if(payload.notification.title==this.state.ticket_id)
            {
            this.setState({currentMessage: ''})
            this.setState({chatMsg: payload.notification.body})
           
            this.setState({chatid: 2})
            this.setState({chattime:(new Date()).toLocaleDateString("en-GB")+' '+(new Date()).toLocaleTimeString("en-GB")})
          this.addMessageDiv()
            }
        })
          }).then(payload => {
              
         
        console.log(payload);
        
        }).catch(err => console.log('failed: ', err));
        
      }
      addMessageDiv = () => {
   
        this.setState({
          divs: [...this.state.divs, <ChatDiv msg={this.state.chatMsg} chatid={this.state.chatid} time={this.state.chattime} />]
        })
        //this.scrollToBottom()
      }
     scrollToBottom(){
        //this.divRef.current.scrollIntoView({ behavior: 'smooth' });
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }
     
      
      componentDidUpdate() {
        this.scrollToBottom();
      }
      fetch(){
        const api = axios.create({
          baseURL: BASE
        });
       
          const user_idd = JSON.parse(localStorage.getItem('userData')).user.id;
          api.get('/support-ticket-replies/'+this.props.ticket_id)
          .then(res => {
            const premessages = res.data;
            
            for (const [i, message] of premessages.entries()) {
              console.log(message)
              this.setState({remoteMessage:message.reply})
              this.setState({chatMsg:message.reply})
              this.setState({chattime:message.reply_date+' '+message.reply_time})
              if(message.user_id==user_idd)
              {
                // this.setState({
                //   messages: [...this.state.messages, new Message({
                //       id:0,
                //       message: message.reply,
                //     })]
                // })
                this.setState({chatid:1})
              
                this.addMessageDiv()
              }
              else
              {
                // this.setState({
                //   messages: [...this.state.messages, new Message({
                //       id:1,
                //       message: message.reply,
                //     })]
                // })
                this.setState({chatid:2})
                
                this.addMessageDiv()
              }

              
             
            }
           
          
          })
        
      }
      componentDidMount() {
        this.scrollToBottom();
        this.fetch();
      } 
    
        
      handleChange=(e)=>{
if(e.target.value.length>0)
{
    this.setState({chatid:1})
    this.setState({currentMessage:e.target.value})
    this.setState({chatMsg:e.target.value})
    this.setState({remoteMessage:''})
    this.setState({chattime:(new Date()).toLocaleDateString("en-GB")+' '+(new Date()).toLocaleTimeString("en-GB")})

   
}
      }
    send=()=>{
       // alert('dddd')
      
       const user_id=JSON.parse(localStorage.getItem('userData')).user.id

       if(this.state.currentMessage!='')
       {
        this.addMessageDiv()
        this.setState({currentMessage:''})
        this.scrollToBottom()
          const api = axios.create({
            baseURL: BASE
          });
          api.post("/support-ticket-reply", {user_id:user_id,ticket_id:this.state.ticket_id,reply:this.state.currentMessage})
          .then((res)=>{
            // if(res.data.indexOf('Success') !== -1)
            // {
             

            // }
        }
            );
         
        }
    };
    closeIssue=()=>{
      //e.preventDefault()
     // alert(ticket_id)
     
      const api = axios.create({
        baseURL: BASE
      });
     
        //const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
        api.post('/support-tickets/closeIssue',{ticket_id:this.state.ticket_id})
        .then(res => {
          const issues = res.data;
          this.setState({issue_status:'Close'})
         // window.location.reload();
      // window.location.reload
        })
    }
      render() {
 
        return (
            
            // <div class="chatbox">
            //   <div class="top-bar">
            //     <div class="avatar"><p>V</p></div>
            //     <div class="name">Voldemort</div>
            //     <div hidden class="icons">
            //       <i class="fas fa-phone"></i>
            //       <i class="fas fa-video"></i>
            //     </div>
            //     <div class="menu">
            //       <div class="dots"></div>
            //     </div>
            //   </div>
            //   <div class="middle">
            //     <div class="voldemort">
            //       <div class="incoming">
            //         <div class="bubble">Hey, Father's Day is coming up..</div>
            //         <div class="bubble">What are you getting.. Oh, oops sorry dude.</div>
            //       </div>
            //       <div class="outgoing">
            //         <div class="bubble lower">Nah, it's cool.</div>
            //         <div class="bubble">Well you should get your Dad a cologne. Here smell it. Oh wait! ...</div>
            //       </div>
            //       <div class="typing">
            //         <div class="bubble">
            //           <div class="ellipsis one"></div>
            //           <div class="ellipsis two"></div>
            //           <div class="ellipsis three"></div>
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            //   <div class="bottom-bar">
            //     <div class="chat">
            //       <input type="text" placeholder="Type a message..." />
            //       <button type="submit" onClick={this.send}><i class="fas fa-paper-plane"></i></button>
            //     </div>
            //   </div>
            // </div>
         
          // Your JSX...
          <MDBContainer>
          <div id="wrapper">
         
          <div id="main">
          <div id="menu">
          <img src={this.state.lawyer_img!=""?this.state.lawyer_img:MyPhoto} alt="Avatar"/>
        
                <p class="welcome">{this.state.lawyer_name} {this.state.lawyer_id} <span id="tick">#{this.state.ticket_no}</span> <MDBBadge color={this.state.issue_status=="Open"?("success"):("light")}>{this.state.issue_status}</MDBBadge> <b></b></p>
                {(this.state.issue_status=="Open")?<p  class="logout"><a class="btn btn-danger" onClick={this.closeIssue} id="exit" >Close Issue</a></p>:""} 
            </div>
          <div className="chat-box-container">
         
          
  
    {/* <div className="row">
          <button className="btn btn-primary" id="backBtn" ><i class="fas fa-back"></i>Back</button>
         
          </div> */}
  <div className="chat-box" ref={this.divRef}>
  
         {this.state.divs} 
         <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }}>
        </div>
     </div>
     </div>
     {(this.state.issue_status=="Open")?  
         <div className="chat-input-container">
         
                 <input className="chat-input" type="text" onChange={this.handleChange} name="chat-input" value={this.state.currentMessage} placeholder="Type a message..."/>
    <button className="chat-submit" type="submit"  onClick={this.send}><i class="fas fa-paper-plane"></i></button>
       
               </div>
                :""} 
         </div>
         </div>
         </MDBContainer>
          // Your JSX...
      //     <div
      //     className='right-panel'>
      //     <MessageList
      //         className='message-list'
      //         lockable={true}
      //         downButtonBadge={10}
      //         dataSource={this.state.messageList} />

      //     <Input
      //         placeholder="Type here........"
      //         defaultValue=""
      //         ref='input'
      //         multiline={true}
      //         // buttonsFloat='left'
      //         onKeyPress={(e) => {
      //             if (e.shiftKey && e.charCode === 13) {
      //                 return true;
      //             }
      //             if (e.charCode === 13) {
      //                 this.refs.input.clear();
      //                 this.send();
      //                 e.preventDefault();
      //                 return false;
      //             }
      //         }}
      //         rightButtons={
      //             <Button
      //                 text='Send'
      //                 onClick={() => this.send()} />
      //         } />
      // </div>
        )
       
      }
  }
  export default Chatwindow;