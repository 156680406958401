import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import {LOCAL_STORAGE_DATA_KEYNAME} from "../../actions/types"
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'; 
import { hasRole } from '../../utils/hasRoleCheck';
import {
  ROLE_ADMIN,
  ROLE_LAWYER,

} from '../../components/common/ConstantValues';
export default class CloseCaseList extends Component {
   state = {
        cases: []
      }
      handleSearch(e){
        let value = e.target.value;
        let id = e.target.name;
        const api = axios.create({
          baseURL: BASE
        });
        if(hasRole([ROLE_ADMIN])){
        api.get('/case-all-close-list?search='+value)
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }else{
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          api.get('/cases/'+user_id+'/0')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }
      }
      onChangeClose(e) {   
        let value = e.target.value;
        let id = e.target.id;
        
        const api = axios.create({
          baseURL: BASE
        });
        
      api.put('/setclosestatus/'+id+'/'+value)
        .then(res => {
          this.fetch();
          alert('sucessfully Updated!!!');
        })
        
    }
      fetch(){
        const api = axios.create({
          baseURL: BASE
        });
        if(hasRole([ROLE_ADMIN])){
        api.get('/case-all-close-list')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }else{
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          api.get('/cases/'+user_id+'/1')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }
      }
      componentDidMount() {
        this.fetch();
      } 
    render() {
        return (
            <div>
              <div className="row py-2">
                 <label className="col-md-1 py-2">Search:</label>
                 <div className="col-md-4">
               <input type="text" className="form-control" placeholder="Search" onChange={this.handleSearch.bind(this)} name="search"></input>
               </div>
               </div>
               <table className="table table-striped table-responsive">
                    <thead>
                        <tr>
                        
                        <th>Case ID</th>
                            <th>Case NO</th>
                            <th>Area</th>
                            <th>Type</th>
                            <th>Court</th>
                        
                            <th>Next date</th>
                            <th>For </th>
                            <th>Result</th>
                            <th>Action</th>
                       
                        </tr>
                    
                    </thead>
                    <tbody>
                    
                    { this.state.cases.map(cases =><tr>
                      <td>{cases.case_id_no}</td>
                      <td>{ cases.case_no}</td>
                      <td>{cases.area_name}</td>
                      <td>{cases.case_type}</td>
                      <td>{cases.court_name}</td>
                
                      <td>{cases.next_date}</td>
                      <td>{cases.case_for}</td>
                      <td>{cases.result}</td>
                      <td>{(hasRole([ROLE_ADMIN])) &&
                    <div><a href={'#/edit-case/'+cases.id} className="btn btn-sm btn-primary"><i className='fa fa-edit'></i> Edit</a></div>}<FormGroup>
      
     
    
    </FormGroup><FormGroup><FormControlLabel
        control={<Switch 
          id={cases.id}
        onChange={this.onChangeClose.bind(this)}
        value={(cases.is_close_all==1)?0:1} />}
        checked= {(cases.is_close_all==1)?'checked':''}
        label="Case-Closed"
      /></FormGroup></td></tr>)}
                </tbody>
                </table> 
            </div>
        )
    }
}
