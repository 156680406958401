import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Link from '@material-ui/core/Link';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import PeopleIcon from '@material-ui/icons/People';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import GavelIcon from '@material-ui/icons/Gavel';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { makeStyles } from '@material-ui/core/styles';
import ReportIcon from '@material-ui/icons/Report';
import { hasRole } from '../../utils/hasRoleCheck';
import {
  ROLE_ADMIN,
  ROLE_LAWYER,

} from '../common/ConstantValues';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));




 const Navlist = () => (
  
  <div>
    <ListItem button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <Link href="#/dashboard">Dashborad</Link>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BusinessCenter />
      </ListItemIcon>
      <Link href='#/lawyer-wise-active-case'>Lawyer Active Cases</Link>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <GavelIcon />
      </ListItemIcon>
      <Link href='#/lawyer-wise-closed-case'>Lawyer Closed Cases</Link>
    </ListItem>
    {(hasRole([ROLE_ADMIN])) &&
     <div>
    <ListItem button>
      <ListItemIcon>
        <BusinessCenter />
      </ListItemIcon>
      <Link href='#/active-case'>Active Cases</Link>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <GavelIcon />
      </ListItemIcon>
      <Link href='#/closed-case'>Closed Cases</Link>
    </ListItem>
    </div>
    }
    <ListItem button>
      <ListItemIcon>
        <NotificationsOffIcon />
      </ListItemIcon>
      <Link href='#/notification-off-case'>Notification Off Cases</Link>
    </ListItem>
    {(hasRole([ROLE_LAWYER])) &&
    <div>
    <ListItem>
      <ListItemIcon>
          <AccountBalanceWalletIcon />
        </ListItemIcon>
      <Link > Payments</Link>
     
    </ListItem>
    <ListItem>
      <ListItemIcon>
       
      </ListItemIcon>
      <Link href='#/pay-bill'>Pay Bill</Link>
    </ListItem>
    <ListItem>
      <ListItemIcon>
       
      </ListItemIcon>
      <Link href='#/payment'>Payment History</Link>
    </ListItem>
    </div>
    }
    {(hasRole([ROLE_ADMIN])) &&
    <div>
      
      <ListItem>
      <ListItemIcon>
      <GavelIcon />
        </ListItemIcon>
      <Link href='#/case-assign'>Lawyer Assign</Link>
    </ListItem>
      <ListItem>
      <ListItemIcon>
      <ReportIcon />
        </ListItemIcon>
      <Link >Reports</Link>
      </ListItem>
      
    <ListItem>
      <ListItemIcon>
      
        </ListItemIcon>
      <Link href='#/case-report'>Lawyer Assign Report</Link>
    </ListItem>
    <ListItem>
      <ListItemIcon>
     
        </ListItemIcon>
      <Link href='#/case-history-report'>Case History Report</Link>
    </ListItem>
    
    
    <ListItem>
      <ListItemIcon>
      <BusinessCenter />
        </ListItemIcon>
      <Link href='#/bulk-upload'>Bulk Upload</Link>
    </ListItem>
    <ListItem>
      <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
      <Link href='#/users'>Users</Link>
    </ListItem>
    <ListItem>
      <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
      <Link > Payment Configuration</Link>
     
    </ListItem>
    <ListItem>
    <ListItemIcon></ListItemIcon>
      <Link href='#/payment-history'>All Payment History</Link><br/>
      </ListItem>
      <ListItem><ListItemIcon></ListItemIcon>
      <Link href='#/payment-request'>Payment Request</Link><br />
      </ListItem>
      <ListItem><ListItemIcon></ListItemIcon>
      <Link href='#/subscription'>Subscription Package</Link>
    </ListItem>
    <ListItem><ListItemIcon></ListItemIcon>
      <Link href='#/promocode'>Promocode</Link>
    </ListItem>
    <ListItem><ListItemIcon></ListItemIcon>
      <Link href='#/promocode-apply'>Promo apply</Link>
    </ListItem>
    <ListItem>
      <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
      <Link >Configuration</Link>
     
    </ListItem>
    
    <ListItem>
    <ListItemIcon></ListItemIcon>
      <Link href='#/case-area'>Area</Link><br/>
      </ListItem>
      <ListItem><ListItemIcon></ListItemIcon>
      <Link href='#/case-type'>Type</Link><br />
      </ListItem>
      <ListItem><ListItemIcon></ListItemIcon>
      <Link href='#/case-for'>Case For</Link>
    </ListItem>
    <ListItem><ListItemIcon></ListItemIcon>
      <Link href='#/expert-category'>Expert Category</Link>
    </ListItem>
    <ListItem><ListItemIcon></ListItemIcon>
      <Link href='#/court-no'>Court No.</Link>
    </ListItem>
   </div> }
    
  </div>
);

export default Navlist;