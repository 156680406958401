import React, {useState, useEffect} from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { BASE } from "../../actions";
import { BASE_URL } from "../../actions";
import { Redirect } from 'react-router';
import AppBarMain from '../shared/AppBarMain';
import DrawerMenu from '../shared/DrawerMenu';
import BkashButton, { IComponentConfig, SuccessFunction } from 'react-bkash';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}{new Date().getFullYear()}
      {'.'}
      <Link color="inherit" href="https://fountainitservice.com">
        Fountain Software & IT Services
      </Link>{' '}
      
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
root: {
  display: 'flex',
},
toolbar: {
  paddingRight: 24, // keep right padding when drawer closed
},
toolbarIcon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  ...theme.mixins.toolbar,
},
appBar: {
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
},
appBarShift: {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
menuButton: {
  marginRight: 36,
},
menuButtonHidden: {
  display: 'none',
},
title: {
  flexGrow: 1,
},
drawerPaper: {
  position: 'relative',
  whiteSpace: 'nowrap',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
drawerPaperClose: {
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: theme.spacing(7),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9),
  },
},
appBarSpacer: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
},
container: {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
},
paper: {
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
},
fixedHeight: {
  height: 240,
},
}));

export default function PayBillForm(props){
  var today = new Date(),
  //date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
   crr_date =today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const [redirect, setredirect] = useState(false);
  const classes = useStyles();
  const id = props.match.params.id;
  const [open, setOpen] = React.useState(true);
  const [isPaymentType, setPaymentType] = useState(false);
  const [packages, setpackages] = useState([]);
  const [subPackage, setSubPackage] = useState({});
  const [isDiscount, setDiscount] = useState(0);
  const [is_check, setIscheck] = useState(0);
  useEffect(() => {
    const api = axios.create({
      baseURL: BASE
    });
   const userid = JSON.parse(localStorage.getItem('userData')).user.id;
  api.get('/subcription-packages')
    .then(res => {
      const packages = res.data;
      setpackages( packages );
    })
    api.get('/subcription-packages/'+props.match.params.id)
    .then(res => {
      const subPackage = res.data;
      setSubPackage( subPackage );
    })
    api.get('/promo-discount/'+userid)
    .then(res => {
      const discount = res.data;
      setDiscount( discount );
    })
    
    
  }, []);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const myCallBack=(success, paymentData)=> { //success true or false
    
    if(success){
      //var data = JSON.stringify(paymentData);
      var data = JSON.parse(paymentData);
     
      if(data.errorCode=='2029'){
        alert('payment failed \r\n'+ data.errorMessage);
        window.location.reload();
      }
    const package_id=document.getElementById('package_id').value;
    const user_id=document.getElementById('user_id').value;
    const discount=document.getElementById('discount').value;
    const vat=document.getElementById('vat').value;
    const trx_date=document.getElementById('trx_date').value;
    const pay_method=2;
    const trx_amount=data.amount;
    const trx_id=data.trxID;
    const payment_id=data.paymentID;
   // const trx_date=data.updateTime;
   
      const api = axios.create({
        baseURL: BASE
      });

       api.post("/web-billpay", {package_id:package_id,
        user_id:user_id,
        pay_method:pay_method,
        trx_amount:trx_amount,
        trx_id:trx_id,
        payment_id:payment_id,
        trx_date:trx_date,
        vat:vat,
        discount:discount
      })
        .then((res) => {
         alert('Successfully Paid');
         
         setredirect(true);
         window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
      
        //alert('success')
        console.log(JSON.stringify(paymentData));
     
    } else {
        alert('payment failed');
        window.location.reload();
    }
   // window.location.reload();
  }
  const handleChange = (e) => {
    //const id=document.getElementById('area').value;
    //case-id-no
    const package_id = e.target.value;
    //setCaseID(id+ );
    const api = axios.create({
      baseURL: BASE
    });
    api.get('/subcription-packages/'+package_id)
    .then(res => {
      const subPackage = res.data;
      setSubPackage( subPackage );
     
  });
}
const handleChangePayment = (e) => {
  const payment_type = e.target.value;
  if(payment_type==1){
    setPaymentType(false);
  }else{
    setPaymentType(true);
  }
  
}
const onCheckboxClick=(e)=>{
  if(e.target.checked==true){
    setIscheck(1);
  }else{
    setIscheck(0);
  }
  
}
  const handleSubmit = (e) => {
      
    e.preventDefault();
    const data = new FormData(e.target);
    const payment_type = data.get('pay_method');
    if(payment_type==1){
    
    const case_type_id = data.get('id');
    const name = data.get('court_no');
    
    const api = axios.create({
      baseURL: BASE
    });
     api.post("/web-billpay", data)
      .then((res) => {
       alert('success');
       setredirect(true);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }
  const options = {
    method: 'POST',
    headers: {Accept: 'application/json', 'Content-Type': 'application/json'}
  };
  
  const handleSuccess: SuccessFunction = (data) => {
		console.log(data, 'Payment successful');
    if(data){
      //var data = JSON.stringify(paymentData);
      var data = JSON.parse(data);
     
      if(data.errorCode=='2029'){
        alert('payment failed \r\n'+ data.errorMessage);
        window.location.reload();
      }
    const package_id=document.getElementById('package_id').value;
    const user_id=document.getElementById('user_id').value;
    const discount=document.getElementById('discount').value;
    const vat=document.getElementById('vat').value;
    const trx_date=document.getElementById('trx_date').value;
    const pay_method=2;
    const trx_amount=data.amount;
    const trx_id=data.trxID;
    const payment_id=data.paymentID;
   // const trx_date=data.updateTime;
   
      const api = axios.create({
        baseURL: BASE
      });

       api.post("/web-billpay", {package_id:package_id,
        user_id:user_id,
        pay_method:pay_method,
        trx_amount:trx_amount,
        trx_id:trx_id,
        payment_id:payment_id,
        trx_date:trx_date,
        vat:vat,
        discount:discount
      })
        .then((res) => {
         alert('Successfully Paid');
         
         setredirect(true);
         window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
      
        //alert('success')
        console.log(JSON.stringify(data));
     
    } else {
        alert('payment failed');
        window.location.reload();
    }
	};

	const handleClose = () => {
		console.log('bKash popup closed');
	};
 
	const config: IComponentConfig = {
		amount: parseInt(parseInt((subPackage.amount)*subPackage.vat/100)+parseInt(subPackage.amount))-parseInt((subPackage.amount*isDiscount)/100),
		bkashScriptURL: 'https://scripts.pay.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout.js',
		createPaymentURL: BASE_URL+'createpayment',
		executePaymentURL: BASE_URL+'executepayment',
		additionalHeaders: {
			Authorization: '',
		},
	};
    
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  if (redirect) {
    return (<Redirect to={'/payment'} />); 
  }
    return (
        <div className={classes.root}>
         <CssBaseline /> 
        <AppBarMain pageName="Pay Bill" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
          <DrawerMenu />
        <main className={classes.content}>
      <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ height: 400, width: '100%' }}>
            <h4 style={{ background:'#ddd',padding:'10px'}}>Subscription </h4>
            <a href="#/pay-bill" className="btn btn-primary float-right btn-sm">Back</a>
            <div className="col-md-6 mx-auto py-3">
            <form onSubmit={handleSubmit}  className={classes.form} noValidate>
            <div className="form-group row">
                <label for="name" className="col-md-5 font-weight-bold">Packege:</label>
                <div className="col-md-7">
                <input type ="hidden" id="user_id" name="user_id" value={JSON.parse(localStorage.getItem('userData')).user.id} />
                <select className="form-control" onChange={handleChange} name="package_id" id="package_id" value={subPackage.id}>
                    
                    { packages.map(pack =>
                      <option value={pack.id}>
                         {pack.name}
                        </option>)}
                        </select>
                        </div>
                
            </div>
            <div className="form-group row">
                <label for="amount" className="col-md-5 font-weight-bold">Duration:</label>
               <div className="col-md-7">{subPackage.duration} Days</div>
                
            </div>
            <div className="form-group row">
                <label for="amount" className="col-md-5 font-weight-bold">Subscription Fee:</label>
               <div className="col-md-7">{subPackage.amount} BDT</div>
                
            </div>
            <div className="form-group row">
                <label for="amount" className="col-md-5 font-weight-bold">Tax/Vat(%):</label>
               <div className="col-md-7">{parseInt((subPackage.amount*subPackage.vat)/100)} BDT ({subPackage.vat})%</div>
                
            </div>
            <div className="form-group row">
                <label for="amount" className="col-md-5 font-weight-bold">Discount Amount:</label>
               <div className="col-md-7">- {parseInt((subPackage.amount*isDiscount)/100)} BDT({isDiscount} %)</div>
                
            </div>
            
            <div className="form-group row  border-bottom border-top pt-3">
           
                <label for="amount" className="col-md-5 font-weight-bold">Total Amount:</label>
               <div className="col-md-7">{parseInt(parseInt(((subPackage.amount)*subPackage.vat/100)+parseInt(subPackage.amount))-parseInt((subPackage.amount*isDiscount)/100))} BDT</div>
              <input type="hidden" id="trx_amount" value={parseInt(parseInt((subPackage.amount)*subPackage.vat/100)+parseInt(subPackage.amount))-parseInt((subPackage.amount*isDiscount)/100)} name="trx_amount" />
              <input type="hidden" id="trx_date" value={crr_date} name="trx_date" />
              <input type="hidden" id="discount" value={parseInt((subPackage.amount*isDiscount)/100)} name="discount" />
              <input type="hidden" id="vat" value={parseInt((subPackage.amount)*subPackage.vat/100)} name="vat" />
              
              <input type="hidden" value='0' name="payment_id" />
            </div>
            <div className="form-group row">
           
                <label for="amount" className="col-md-5 font-weight-bold">Payment Type:</label>
               <div className="col-md-7">
                 <label className="pr-3"><input type="radio" value="1" id="payment_type" defaultChecked={true} onChange={handleChangePayment} name="pay_method"/> Cash</label>
                 <label className="px-3"><input type="radio" value="2" id="payment_type" onChange={handleChangePayment} name="pay_method"/> Online</label>
               </div>
                
            </div>
            <div className="form-group row">
           
            <label><input type="checkbox" name="aggree" value={is_check} required onClick={onCheckboxClick} /> I agree to the <a href="#/terms" target="_blank">Terms & Conditions</a>.</label>
              
                
            </div>
            <div className="form-group row">
              <div class="col-md-5"></div>
            <div className="col-md-7">
             {(is_check==1)?<div>
              { isPaymentType?
              /*<BkashButton
              color="primary"
             btnText="Pay With Bkash"
             //amount ={parseInt(parseInt(((subPackage.amount)*subPackage.vat/100)+parseInt(subPackage.amount))-parseInt((subPackage.amount*isDiscount)/100))}
             amount ="10"
             createPaymentURL= {BASE_URL+'createpayment'}
             executePaymentURL= {BASE_URL+'executepayment'}
             callBack={myCallBack}
           
          />*/<BkashButton onSuccess={handleSuccess} onClose={handleClose} config={config}>
			<button >Pay with bKash</button>
		</BkashButton>: <Button
            type="submit"
            
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Confirm & Pay 
          
          
          </Button>}
          
          </div>:<div className="disabled btn btn-sm">Confirm & Pay</div>}
          </div>
          </div>
            </form>
            </div>
         </div>
         </Container>
        
         </main>
        
        </div>
    )
}
