import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../../actions";

export default class CaseForList extends Component {
    state = {
        types: []
      }
      
      componentDidMount() {
        const api = axios.create({
            baseURL: BASE
          });
          api.get(`/case-for`)
          .then(res => {
              console.log(res.data);
            const types = res.data;
            this.setState({ types });
          })
      }
      
      deleteRow(id, e){
        const api = axios.create({
          baseURL: BASE
        });
        api.delete(`case-for/destroy/${id}`)
          .then(res => {
            console.log(res);
            console.log(res.data);
            window.location.reload(false);
           // const posts = this.state.posts.filter(item => item.id !== id);
            //this.setState({ posts });
          })
      
      }
    render() {
        return (
            <div>
                 <h1></h1>
  
  <table className="table table-bordered">
      <thead>
        <tr>
            <th>ID</th>
            <th>Name</th>
            
            <th>Action</th>
        </tr>
      </thead>

      <tbody>
        {this.state.types.map((post) => (
          <tr>
            <td>{post.id}</td>
            <td>{post.name}</td>
        
            <td>
              <button className="btn btn-danger btn-sm" onClick={(e) => this.deleteRow(post.id, e)}>Delete</button>
              <a href={'#/case-for-edit/'+post.id} className="btn btn-primary btn-sm">Edit</a>
            </td>
          </tr>
        ))}
      </tbody>

  </table>
            </div>
        )
    }
}
