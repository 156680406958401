import React, {useState, useEffect} from 'react'
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Modal} from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import AccountSettingList from '../dashboard/AccountSettingList';
import axios from 'axios';
import { BASE } from "../../actions";
import { Redirect } from 'react-router';
import AppBarMain from '../shared/AppBarMain';


function VerificationModal(props) {

  const classes = useStyles();
  const email=props.email.useremail;
  const user_name=props.user_name.username;
  const user_id = JSON.parse(localStorage.getItem('userData')).user.id;

  const handleRegister = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const verif_code=data.get('vcode');

    const api = axios.create({
      baseURL: BASE
    });
    api.post("/user/email/verify", {user_id:user_id,email:email,verif_code:verif_code})
    .then((res)=>{
      if(res.data.message=="success")
      {
        alert("success");
        var user= JSON.parse(localStorage.getItem('userData'));
      
        user.user.email=email;
        localStorage.setItem('userData',JSON.stringify(user));
        
        const user_email_info1 = JSON.parse(localStorage.getItem('userData')).user.email;
   
        props.onHide();
        window.location.href = '#/account-setting';
        
      }
      else
      {
        alert("Wrong OTP");
      }
      
    })
     
      .catch(res => {
     
        
    })
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Verify Email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Email has been sent to {email}</h4>
        <p>
          <div>
          <form onSubmit={handleRegister}  className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="vcode"
                name="vcode"
                variant="outlined"
                
                fullWidth
                id="vcode"
                label="OTP Code"
                autoFocus
              />
               {/* {(emailerror)?<p className="text-danger">{error}</p>:''} */}
            </Grid>
            
           
           
            
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Verify
          </Button>
          <Grid hidden container justify="flex-end">
            <Grid item>
              <Link href="{handleLogin}" variant="body2">
                Don't have the code? Resend
              </Link>
            </Grid>
          </Grid>
        </form>
      
          </div>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}{new Date().getFullYear()}
      {'.'}
      <Link color="inherit" href="https://fountainitservice.com">
        Fountain Software & IT Services
      </Link>{' '}
      
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
root: {
  display: 'flex',
},
toolbar: {
  paddingRight: 24, // keep right padding when drawer closed
},
toolbarIcon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  ...theme.mixins.toolbar,
},
appBar: {
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
},
appBarShift: {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
menuButton: {
  marginRight: 36,
},
menuButtonHidden: {
  display: 'none',
},
title: {
  flexGrow: 1,
},
drawerPaper: {
  position: 'relative',
  whiteSpace: 'nowrap',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
drawerPaperClose: {
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: theme.spacing(7),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9),
  },
},
appBarSpacer: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
},
container: {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
},
paper: {
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
},
fixedHeight: {
  height: 240,
},
}));

export default function EditProfile() {
  const [modalShow, setModalShow] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [redirect, setredirect] = useState(false);
  const [user, setUser] = useState([]);
  const [lawyer, setLawyer] = useState([]);
  const [post, setPost] = useState({});
  const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
  const user_email_info = JSON.parse(localStorage.getItem('userData')).user.email;
  const user_info = JSON.parse(localStorage.getItem('userData'));
  const api = axios.create({
    baseURL: BASE
  });

  useEffect(() => {
    api.get('/users-lawyer/'+user_id)
    .then(res => {
      const cases = res.data;
      setPost( cases );
      
    });
    api.get('/users/'+user_id)
    .then(res => {
      const users = res.data;
      setUser(users.data);
      setLawyer(users.lawyer_id);

     
    });
  }, []);

  const handleSubmit = (e) => {
      
    e.preventDefault();
    
    const data = new FormData(e.target);
    const useremail = data.get('useremail');
    const username = data.get('username');
    const userlin = data.get('lin');
    const lawyerid = data.get('lawyerid');
    setUserName({username})
    setUserEmail({useremail})
    const api = axios.create({
      baseURL: BASE
    });
    const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
    const user_email = JSON.parse(localStorage.getItem('userData')).user.email;
   
     api.post("user/updateProfile", {'userid':user_id,'useremail':useremail,'username':username,'userlin':userlin})
      .then((res) => {
        
        const msg=res.data.message;
        console.log(useremail);
        console.log(user_email);
        if(msg== 'success')
        {
        var user= JSON.parse(localStorage.getItem('userData'));
        user.user.name=username;
        user.lin=userlin;
        localStorage.setItem('userData',JSON.stringify(user));
      if(useremail!="" && useremail!=user_email)
      {
          const api = axios.create({
            baseURL: BASE
          });
          api.post("/user/email/sendCode", {user_id:user_id,name:username,email:useremail})
          .then((res)=>{
        
            if(res.data.message=="success")
            {
              setModalShow(true)
            }
        });


      }else{
        setredirect(true);
      }
  
        }
        else
        {
          alert(res.data.message);
          setredirect(true);
        }
     
      
       
      })
      .catch((error) => {
       
      });
    
  }
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  if (redirect) {
    return (<Redirect to={'/account-setting'} />); 
  }
    return (
        <div className={classes.root}>
        <CssBaseline />
        <VerificationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        email={userEmail}
        user_name={userName}
       
      />
        <AppBarMain pageName="Edit Account Setting" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
          <h3>
              Case Diary
          </h3>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <AccountSettingList />
         
        </Drawer>
        <main className={classes.content}>
      <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ height: 400, width: '100%' }}>
            <h4 style={{ background:'#ddd',padding:'10px'}}>Edit Account Setting</h4>
            <div className="row">
                <div className="col-md-4">
                    
                </div>
                <div className="col-md-8">
                    <form onSubmit={handleSubmit}>
                      <div class="py-2">
                        <label>Name:</label>
                        <input type="text" class="form-control" name="username" defaultValue={user.name} required/>
                      </div>
                      <div class="py-2">
                      <label>Phone:</label>
                        <input type="text" class="form-control" name="usermobile" disabled  defaultValue={user.phone} required/>
                      </div>
                      <div class="py-2">
                      <label>Email:</label>
                        <input type="email" class="form-control" name="useremail" defaultValue={user.email}  />
                      </div>
                      <div class="py-2">
                      <label>Lawyer ID:</label>
                        <input type="text" class="form-control" name="lawyerid2" defaultValue={lawyer.lawyer_id} disabled />
                      </div>
                      <div class="py-2">
                      <label>LIN Number:</label>
                        <input type="text" class="form-control" name="lin" defaultValue={user.lin} />
                      </div>
                     <button type="submit" class="btn btn-primary btn-sm">Update</button>
                     <a class="btn btn-sm btn-primary" href="#/account-setting">Back</a>
                    </form>
                </div>
                </div>
         </div>
         </Container>
        
         </main>
        
        </div>
    )
}
