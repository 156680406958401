import React, {useState, useEffect} from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountSettingList from '../dashboard/AccountSettingList';
import Button from '@material-ui/core/Button';
import AppBarMain from '../shared/AppBarMain';
import axios from 'axios';
import { BASE } from "../../actions";
import { Redirect } from 'react-router';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}{new Date().getFullYear()}
      {'.'}
      <Link color="inherit" href="https://fountainitservice.com">
        Fountain Software & IT Services
      </Link>{' '}
      
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
root: {
  display: 'flex',
},
toolbar: {
  paddingRight: 24, // keep right padding when drawer closed
},
toolbarIcon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  ...theme.mixins.toolbar,
},
appBar: {
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
},
appBarShift: {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
menuButton: {
  marginRight: 36,
},
menuButtonHidden: {
  display: 'none',
},
title: {
  flexGrow: 1,
},
drawerPaper: {
  position: 'relative',
  whiteSpace: 'nowrap',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
drawerPaperClose: {
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: theme.spacing(7),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9),
  },
},
appBarSpacer: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
},
container: {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
},
paper: {
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
},
fixedHeight: {
  height: 240,
},
}));
export default function BillingAddress(props) {
    const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [redirect, setredirect] = useState(false);
  const [post, setPost] = useState({});
  const [countries, setCountries] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [thanas, setThanas] = useState([]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleSubmit = (e) => {
      
    e.preventDefault();
  
    const data = new FormData(e.target);
    const case_type_id = data.get('id');
    const name = data.get('court_no');
  
    const api = axios.create({
      baseURL: BASE
    });
     api.post("/billpay", data)
      .then((res) => {
       alert('success');
       setredirect(true);
      })
      .catch((error) => {
        console.log(error);
      });
    
  }
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleChangeCountry = e =>{
   
    const api = axios.create({
      baseURL: BASE
    });
    api.get('/divisions/'+e.target.value)
    .then(res => {
      const divisions = res.data;
      setDivisions( divisions );
     
    });
  }
  const handleChangedivision = e =>{
   
    const api = axios.create({
      baseURL: BASE
    });
    api.get('/districts/'+e.target.value)
    .then(res => {
      const district = res.data;
      setDistricts( district );
     
    });
  }
  const handleChangeDistrict = e =>{
   
    const api = axios.create({
      baseURL: BASE
    });
    api.get('/thanas/'+e.target.value)
    .then(res => {
      const thanas = res.data;
      setThanas( thanas );
     
    });
  }
  useEffect(() => {
    const api = axios.create({
      baseURL: BASE
    });
    api.get('/countries')
    .then(res => {
      const countries = res.data;
      setCountries( countries );
     
    });
    
    
    
  
  }, []);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarMain counter={props.counter} pageName="Billing Address" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
          <h3>
              Case Diary
          </h3>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <AccountSettingList />
         
        </Drawer>
      <main className={classes.content}>
      <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ height: 400, width: '100%' }}>
            <h4 style={{ background:'#ddd',padding:'10px'}}>Billing Address</h4>
            <form  className={classes.form} noValidate style={{ width:'400px',margin:'0 auto'}}>
              
    
        <div className="form-group">
              <label for="case_type">Country</label>
            <select className="form-control mx-2" name="country" onChange={handleChangeCountry}>
                        <option value="">Select Country</option>
                        { countries.map(country =>
                      <option value={country.id}>
                         {country.name}
                        </option>)}
                      
            </select>
        </div>
        <div className="form-group">
              <label for="case_for">Division</label>
              <select className="form-control mx-2" onChange={handleChangedivision} id="case_for" name="case_for">
                    <option value="">Select Division</option>
                    { divisions.map(division =>
                      <option value={division.id}>
                         {division.name}
                        </option>)}
              </select>
        </div>
        <div className="form-group">
              <label for="case_for">District</label>
              <select className="form-control mx-2" id="case_for" onChange={handleChangeDistrict} name="case_for">
                    <option value="">Select District</option>
                    { districts.map(district =>
                      <option value={district.id}>
                         {district.name}
                        </option>)}
              </select>
        </div>
        <div className="form-group">
              <label for="case_for">Thana</label>
              <select className="form-control mx-2" id="case_for" name="case_for">
                    <option value="">Select Thana</option>
                    { thanas.map(thana =>
                      <option value={thana.id}>
                         {thana.name}
                        </option>)}
              </select>
        </div>
      
        <div className="form-group">
              <label for="advocate_two">Billing Address</label>
              <input type="text"
               className="form-control"
                name="advocate_two"
                placeholder="Enter Address"
                 />
        </div>
        <div className="form-group">
              <label for="party_one">Postal Code</label>
              <input id="party_one"
            className="form-control"
            name="party_one"
            placeholder="Enter Postal Code"
            />
        </div>
        
        
        <div className="form-group">
              <label for="result">Contact Number</label>
        <input id="result"
         className="form-control"
          name="result"
           placeholder="Enter Contact number"
            />
        </div>
          <Button
            type="submit"
            style={{ margin: 8 }}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
          </form>
         </div>
         </Container>
        
         </main>
        
        </div>
    )
}
