import React, {useState, useEffect} from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import ProfileMenu from "../../components/common/ProfileMenu";
import AccountSettingList from '../dashboard/AccountSettingList';
import { BASE,BASE_IMAGE } from "../../actions";
import axios from 'axios';
import Resizer from "react-image-file-resizer";
import AppBarMain from '../shared/AppBarMain';
import DrawerMenu from '../shared/DrawerMenu';
import ReactRoundedImage from "react-rounded-image";
import {Image} from 'react-bootstrap';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}{new Date().getFullYear()}
      {'.'}
      <Link color="inherit" href="https://fountainitservice.com">
        Fountain Software & IT Services
      </Link>{' '}
      
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
root: {
  display: 'flex',
},
toolbar: {
  paddingRight: 24, // keep right padding when drawer closed
},
toolbarIcon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  ...theme.mixins.toolbar,
},
appBar: {
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
},
appBarShift: {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
menuButton: {
  marginRight: 36,
},
menuButtonHidden: {
  display: 'none',
},
title: {
  flexGrow: 1,
},
drawerPaper: {
  position: 'relative',
  whiteSpace: 'nowrap',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
drawerPaperClose: {
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: theme.spacing(7),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9),
  },
},
appBarSpacer: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
},
container: {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
},
paper: {
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
},
fixedHeight: {
  height: 300,
},
}));
export default function Profile() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  
  const user_photo = JSON.parse(localStorage.getItem('userData')).user.photo;
  const [image, setImage] = React.useState(user_photo);
  const [user, setUser] = useState([]);
  const [lawyer, setLawyer] = useState([]);
  const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 140, 140, 'JPEG', 100, 0,
    uri => {
      resolve(uri);
    }, 'base64' );
});
  const onImageChange = async(event) => {
    if (event.target.files && event.target.files[0]) {
     const file=event.target.files[0];
     const image = await resizeFile(file);
    
     const api = axios.create({
      baseURL: BASE
    });
    api.post("/user/photo", {user_id:user_id,base64image:image,is_web:1})
    .then((res)=>{
      
      if(res.data!=="failed")
      {
      var user= JSON.parse(localStorage.getItem('userData'));
      
      user.user.photo=res.data;
      localStorage.setItem('userData',JSON.stringify(user));    
      
        setImage(res.data);
      }
    });
     
    
    }
  }
  useEffect(() => {
    const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
    const api = axios.create({
      baseURL: BASE
    });
    api.get('/users/'+user_id)
    .then(res => {
      const users = res.data;
      
      
      setUser(users.data);
      setLawyer(users.lawyer_id);
    });
  }, []);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarMain pageName="Account Setting" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
          <h3>
              Case Diary
          </h3>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <AccountSettingList />
         
        </Drawer>
        <main className={classes.content}>
      <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ height: 400, width: '100%' }}>
            <h4 style={{ background:'#ddd',padding:'10px'}}>Account Setting</h4>
            <div className="row">
                <div className="col-md-4">
                <div class="image-upload"  >
  <label style={{ cursor: 'pointer' }}>
  <ReactRoundedImage
  
          image={image!=null?(BASE_IMAGE+image):"/images/dummy-profile.png"}
          roundedColor="#321124"
          imageWidth="140"
          imageHeight="140"
          roundedSize="0"
          hoverColor="#DD1144"
        /> 
        

  <input style={{ display:'none'}} id="file-input" type="file" onChange={onImageChange}/>
  </label>
</div>
                    <input hidden type="file" onChange={onImageChange} className="filetype" id="group_image"/>
                    
                   

                </div>
                <div className="col-md-8">
                    <a href="#/edit-profile" className="text-right btn btn-primary btn-sm"><i className="fa fa-edit"></i></a>
                    <p><strong>Name:</strong>  {JSON.parse(localStorage.getItem('userData')).user.name} </p>
                    
                    <p><strong>Phone:</strong> {JSON.parse(localStorage.getItem('userData')).user.phone} <i class="fa fa-check-circle text-success" title="Verified" aria-hidden="true"></i></p>
                    
                   
                    <p><strong>Email:</strong> {user.email}{user!=null?(
                     <i class="fa fa-check-circle text-success" title="Verified" aria-hidden="true"></i>):""}</p>
                   
       
                    <p><strong>Lawyer ID:</strong> {lawyer.lawyer_id} </p>
                    <p><strong>LIN Number:</strong>{user.lin}</p>
                </div>
                
                </div>
         </div>
         </Container>
        
         </main>
        
        </div>
    )
}
