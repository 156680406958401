import React, {useState, useEffect} from 'react'
import Navlist from '../dashboard/Navlist';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { BASE } from "../../actions";
import { hasRole } from "../../utils/hasRoleCheck";
import ProfileMenu from "../common/ProfileMenu";
import AsyncSelect from 'react-select/async';
import ReportHistoryList from './ReportHistoryList';
import AppBarMain from "../shared/AppBarMain";
import DrawerMenu from "../shared/DrawerMenu";
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}{new Date().getFullYear()}
        {'.'}
        <Link color="inherit" href="https://fountainitservice.com">
          Fountain Software & IT Services
        </Link>{' '}
        
      </Typography>
    );
  }
  
  const drawerWidth = 240;
  
  const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  }));
 
export default function CaseHistoryReport() {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const [age,setAge] = React.useState('');
    const [case_type,setCaseType] = React.useState('');
    const [case_for,setCaseFor] = React.useState('');
    const [error, setError] = useState(null);
    const [emailerror, setEmailError] = useState(null);
    const [passworderror, setPasswordError] = useState(null);
    const [repassworderror, setRePasswordError] = useState(null);
  
    const [load, setLoad] = useState(null);
   
 
    const handleDrawerClose = () => {
      setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [post, setPost] = useState([]);
    const [types, setType] = useState([]);
    const [casefors, setCase] = useState([]);
    const [courtno, setCourtno] = useState([]);
    const [areas, setAreas] = useState([]);
 // const id = props.match.params.id;
 
  const api = axios.create({
    baseURL: BASE
  });
  
  
 
  
 
//   const fetch=()=>{
//     const api = axios.create({
//       baseURL: BASE
//     });
    
//     api.get('/cases')
//       .then(res => {
//         const cases = res.data;
//         this.setState({ cases });
//       })
//     }


   const [inputValue, setValue] = useState('');
   const [selectedValue, setSelectedValue] = useState(null);
  
   // handle input change event
   const handleInputChange = value => {
       //console.log(value);
     setValue(value);
   };
  
   // handle selection
   const handleChange = value => {
       //console.log(value);
       setSelectedValue(value);
     api.get(`case-report-history?search=${value.case_id_no}`)
     .then((response) => {
        setPost(value);
     });
   }
  
   // load options using API call
   const loadOptions = (inputValue) => {
     return api.get(`case-report-history?search=${inputValue}`)
     .then((response) => {
        //setPost(response.data);
        return response.data;
     });
     
     ;
   };
      return (
          <div className={classes.root}>
          <CssBaseline />
              <AppBarMain pageName="Case History Report" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
              <DrawerMenu />
          <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <div style={{ height: 400, width: '100%' }}>
              <h4 style={{ background:'#ddd',padding:'10px'}}>Case History Report</h4>
             
             <ReportHistoryList></ReportHistoryList>
           </div>
           </Container>
          
           </main>
          
          </div>
      )
}
