import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import {LOCAL_STORAGE_DATA_KEYNAME} from "../../actions/types"
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { hasRole } from '../../utils/hasRoleCheck';
import {
  ROLE_ADMIN,
  ROLE_LAWYER,

} from '../../components/common/ConstantValues';
export default class ActiveCaseList extends Component {

    state = {
        cases: [],
        checked: '',
      }
      
      onChange(e) {   
        let value = e.target.value;
        let id = e.target.id;
        
        const api = axios.create({
          baseURL: BASE
        });
        
      api.put('/setnotifstatus/'+id+'/'+value)
        .then(res => {
          this.fetch();
          alert('sucessfully Updated!!!');
        })
        
    }
    handleSearch(e){
      let value = e.target.value;
      let id = e.target.name;
      const api = axios.create({
        baseURL: BASE
      });
      if(hasRole([ROLE_ADMIN])){
      api.get('/case-all-active-list?search='+value)
        .then(res => {
          const cases = res.data;
          this.setState({ cases });
        })
      }else{
        const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
        api.get('/cases/'+user_id+'/0')
        .then(res => {
          const cases = res.data;
          this.setState({ cases });
        })
      }
    }
    onChangeClose(e) {   
      let value = e.target.value;
      let id = e.target.id;
      
      const api = axios.create({
        baseURL: BASE
      });
      
    api.put('/setclosestatus/'+id+'/'+value)
      .then(res => {
        this.fetch();
        alert('sucessfully Updated!!!');
      })
      
  }
    
      fetch(){
        const api = axios.create({
          baseURL: BASE
        });
        if(hasRole([ROLE_ADMIN])){
        api.get('/case-all-active-list')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }else{
          const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
          api.get('/cases/'+user_id+'/0')
          .then(res => {
            const cases = res.data;
            this.setState({ cases });
          })
        }
      }
      componentDidMount() {
        this.fetch();
      } 
      downloadSample(){
        const parentId = this.state.parentID.id;
        const request = this.props.getSampleBuildingFile(parentId);
        request.then((response) => {
          
          if (response.payload.data != null) {
            const url =BASE + "/"+ response.payload.data;
            window.open(url,"_blank");
          } else {
           
          }
        }).catch((error) => {
          
        });
      }
    render() {
        return (
            <div>
              {(hasRole([ROLE_ADMIN])) &&
               <a className="btn btn-primary mr-2 py-1"
                   href="#/bulk-upload" id='download'> <i className="fa fa-upload"></i> Bulk Upload
                 </a>
               }
               <div className="row py-2">
                 <label className="col-md-1 py-2">Search:</label>
                 <div className="col-md-4">
               <input type="text" className="form-control" placeholder="Search" onChange={this.handleSearch.bind(this)} name="search"></input>
               </div>
               </div>
               
              <table className="table table-striped table-responsive">
                    <thead>
                        <tr style={{width: '100%'}}>
                          <th style={{width: '5%'}}>SL No.</th>
                            <th style={{width: '5%'}}>Case ID</th>
                            <th style={{width: '5%'}}>Case NO</th>
                            <th style={{width: '5%'}}>Area</th>
                            <th style={{width: '5%'}}>Type</th>
                            <th style={{width: '5%'}}>Court</th>
                          
                            <th style={{width: '5%'}}>Next date</th>
                            <th style={{width: '5%'}}>For </th>
                            <th style={{width: '5%'}}>Result</th>
                            <th style={{width: '5%'}}>Action</th>
                        </tr>
                    
                    </thead>
                    <tbody>
                    { this.state.cases.map((cases, index) =><tr>
                      <td>{++index}</td>
                      <td>{cases.case_id_no}</td>
                      <td>{ cases.case_no}</td>
                      <td>{cases.area_name}</td>
                      <td>{cases.case_type}</td>
                      <td>{cases.court_name}</td>
                      <td>{cases.next_date}</td>
                      <td>{cases.case_for}</td>
                      <td>{cases.result}</td><td>
                    {(hasRole([ROLE_ADMIN])) &&
                    <div><a href={'#/edit-case/'+cases.id} className="btn btn-sm btn-primary"><i className='fa fa-edit'></i> Edit</a></div>} 
                    
                    <FormGroup>
                  <FormControlLabel
        control={<Switch
          id={cases.id}
          onChange={this.onChange.bind(this)}
          value={(cases.is_notify_all==1)?0:1}
          checked= {(cases.is_notify_all==1)?'checked':''}
        color="primary"
      />}
        label="Notification"
      />
   
    </FormGroup>
    <FormGroup>
      <FormControlLabel
        control={<Switch
          id={cases.id}
          onChange={this.onChangeClose.bind(this)}
          value={(cases.is_close_all==1)?0:1}
          checked= {(cases.is_close_all==1)?'checked':''}
        color="primary"
      />}
        label="Case-Closed" />
      </FormGroup>
      </td></tr>)}
                </tbody>
                </table>  
            </div>
        )
    }
}
