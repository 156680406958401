import AppBar from '@material-ui/core/AppBar';
import React, {useState, useEffect} from 'react'
import Toolbar from '@material-ui/core/Toolbar';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ProfileMenu from '../common/ProfileMenu';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { BASE } from "../../actions";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
export default function AppBarMain(props){
  
const drawerWidth = 240;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));
  const classes = useStyles();
  const [post, setPost] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
   
    const api = axios.create({
      baseURL: BASE
    });
    const user_id = JSON.parse(localStorage.getItem('userData')).user.id
    api.post('/notification/allstatus/'+user_id)
    .then(res => {
      //const cases = res.data;
      //setPost( cases );
     
    })
  };
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const api = axios.create({
      baseURL: BASE
    });
    const user_id = JSON.parse(localStorage.getItem('userData')).user.id
    api.get('/getmessages/'+user_id)
    .then(res => {
      const cases = res.data;
      setPost( cases );
     
    })
  
  }, []);
  //const [open, setOpen] = useState(true);
    return(
      
      <AppBar position="absolute" className={clsx(classes.appBar && classes.appBarShift)}>
      
        <Toolbar className={classes.toolbar}>
        <Grid item xs={12} md={11} lg={11}>
           <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          > 
             <MenuIcon />
          </IconButton> 
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {props.pageName}
          </Typography>
          </Grid>
         
          <Button aria-controls="simple-menu" color="inherit" aria-haspopup="true" onClick={handleClick}>
          <IconButton color="inherit">
            <Badge badgeContent={props.counter} color="secondary">
              <NotificationsIcon />
            </Badge>
            
          </IconButton>
</Button>
<Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
  height="100"
>
{ post.map(postr =>
  <MenuItem onClick={handleClose}>{postr.message}</MenuItem>
)}
</Menu>
          
          <ProfileMenu />
        </Toolbar>
        
      </AppBar>
      
    
      );
      }