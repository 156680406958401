import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import { ForgetPassword } from '../components/ForgetPassword';
import {Login} from '../components/Login';
import SignUp from '../components/sign-up/SignUp';
import UserList from '../components/user/UserList';
import CreateUser from '../components/user/CreateUser';
import EditUser from '../components/user/EditUser';
import Payment from '../components/payment/Payment';
import CLoseCase from '../components/close-case/CLoseCase';
import  NotifictionCase  from "../components/notification/Notification";
import  ActiveCase  from "../components/active-case/ActiveCase";
import ForntPage from '../components/ForntPage';
import AddCase from '../components/active-case/AddCase';
import CaseType from '../components/configuration/CaseType';
import AddCaseType from '../components/configuration/AddCaseType';
import EditCaseType from '../components/configuration/EditCaseType';
import requireAuth from '../utils/requireAuth';
import { hasRole } from '../utils/hasRoleCheck';
import CaseArea from '../components/configuration/area/CaseArea';
import Profile from '../components/account_setting/Profile';
import BillingAddress from '../components/account_setting/BillingAddress';
import LegalExpertCategory from '../components/account_setting/LegalCategoryExpert';
import {
  ROLE_ADMIN,
  ROLE_LAWYER,

} from '../components/common/ConstantValues';
import CaseFor from '../components/configuration/casefor/CaseFor';
import CaseForAdd from '../components/configuration/casefor/CaseForAdd';
import CaseForEdit from '../components/configuration/casefor/CaseForEdit';

import CaseExpertCategory from '../components/configuration/expert_category/CaseExpertCategory';
import CaseExpertCategoryAdd from '../components/configuration/expert_category/CaseExpertCategoryAdd';
import CaseExpertCategoryEdit from '../components/configuration/expert_category/CaseExpertCategoryEdit';
import ServiceConfig from '../components/account_setting/ServiceConfig';
import Role from '../components/configuration/role/Role';

import CourtNo from '../components/configuration/court_no/CourtNo';
import CourtNoAdd from '../components/configuration/court_no/CourtNoAdd';
import CourtNoEdit from '../components/configuration/court_no/CourtNoEdit';
import CaseBulKUpload from '../components/active-case/CaseBulkUpload';
import CaseAreaAdd from '../components/configuration/area/CaseAreaAdd';
import CaseAreaEdit from '../components/configuration/area/CaseAreaEdit';
import CaseAssign from '../components/case-assign/CaseAssign';
import CaseReport from '../components/case-report/CaseReport';
import BulkUpload from '../components/bulk-upload/BulkUpload';
import CaseHistoryReport from '../components/case-report/CaseHistoryReport';
import EditCase from '../components/active-case/CaseEdit';
import LawyerActiveCase from '../components/lawyer-case/LawyerActiveCase';
import LawyerCloseCase from '../components/lawyer-case/LawyerCloseCase';
import EditProfile from '../components/account_setting/EditProfile';
import Paybill from '../components/payment/Paybill';
import PayBillForm from '../components/payment/PayBillForm';
import PaymentHistory from '../components/payment/PaymentHistory';
import Subscription from '../components/subscription/Subscription';
import SubscriptionAdd from '../components/subscription/SubscriptionAdd';
import SubscriptionEdit from '../components/subscription/SubscriptionEdit';
import PaymentRequest from '../components/payment/PaymentRequest';

import Promocode from '../components/promocode/Promocode';
import AddPromocode from '../components/promocode/AddPromocode';
import EditPromocode from '../components/promocode/EditPromocode';
import PromocodeApply from '../components/promocode/PromocodeApply';
import  Chat  from "../components/help-support/Chat";
import  OpenIssues  from "../components/help-support/OpenIssues";
import AddIssue from '../components/help-support/AddIssue'
import PromoApply from '../components/payment/PromoApply';
import ChangePassword from '../components/account_setting/ChangePassword';
import Terms from '../components/terms/Term';
import FAQ from '../components/FAQ';
import CaseBackDateReport from "../components/case-report/CaseBackDateReport";
import SubscriptionExpiryReport from "../components/case-report/SubscriptionExpiryReport";

const MainRoute = (props)=>(
<main>
    <Switch>
	 <Route path="/chat/:ticket_no/:lawyer_name/:lawyer_id/:ticket_id/:issue_status"  component={Chat} />
       
        <Route path="/issues"  component={OpenIssues} />
        <Route path="/add-issue"  component={AddIssue} />
        <Route path="/login"  component={Login} />
        <Route path="/" exact component={ForntPage} />
        <Route path="/terms" component={Terms} />
        <Route path="/faq" component={FAQ} />
        <Route path="/forgot-password" component={ForgetPassword} />
        <Route path="/registration" component={SignUp} />
        <Route path="/dashboard" 
        component={requireAuth(() => (<Dashboard counter={props.counter} />))}
        />
        
        <Route path="/users" exact component={requireAuth(UserList)} />
        <Route path="/users/create" component={requireAuth(CreateUser)}/>
        <Route path="/users/edit/:id" component={requireAuth(EditUser)}/>
        <Route path='/payment' component={requireAuth(Payment)} />
        <Route path='/closed-case' component={requireAuth(CLoseCase)} />
        <Route path='/notification-off-case' component={requireAuth(NotifictionCase)} />
        <Route path='/active-case' component={requireAuth(ActiveCase)} />
        <Route path='/lawyer-wise-active-case'  component={requireAuth(() => (<LawyerActiveCase counter={props.counter} />))} />
        <Route path='/lawyer-wise-closed-case' component={requireAuth(LawyerCloseCase)} />
        <Route path='/add-case' component={requireAuth(() => (<AddCase counter={props.counter} />))}/>
        <Route path='/edit-case/:id' component={requireAuth(EditCase)} />
        <Route path='/case-bulk-upload' component={requireAuth(CaseBulKUpload)} />
        
        <Route path='/case-assign' component={requireAuth(CaseAssign)} />
        <Route path='/case-report' component={requireAuth(CaseReport)}/>
        <Route path='/bulk-upload' component={requireAuth(BulkUpload)} />

        <Route path='/case-type' component={requireAuth(CaseType)} />
        <Route path='/add-case-type' component={requireAuth(AddCaseType)} />
        <Route path='/edit-case-type/:id' component={requireAuth(EditCaseType)} />
        
        <Route path="/case-history-report" component={requireAuth(CaseHistoryReport)} />

        <Route path="/subscription-expiry-report" component={requireAuth(SubscriptionExpiryReport)} />
        <Route path="/case-back-date-report" component={requireAuth(CaseBackDateReport)} />

        <Route path='/case-area' component={requireAuth(CaseArea)} />
        <Route path='/case-area-add' component={requireAuth(CaseAreaAdd)} />
        <Route path='/case-area-edit/:id' component={requireAuth(CaseAreaEdit)} />
        
        <Route path='/case-for' component={requireAuth(CaseFor)} />
        <Route path='/case-for-add' component={requireAuth(CaseForAdd)} />
        <Route path='/case-for-edit/:id' component={requireAuth(CaseForEdit)} />
        
        <Route path='/expert-category' component={requireAuth(CaseExpertCategory)} />
        <Route path='/expert-category-add' component={requireAuth(CaseExpertCategoryAdd)} />
        <Route path='/expert-category-edit/:id' component={requireAuth(CaseExpertCategoryEdit)} />

        <Route path='/court-no' component={requireAuth(CourtNo)} />
        <Route path='/court-no-add' component={requireAuth(CourtNoAdd)} />
        <Route path='/court-no-edit/:id' component={requireAuth(CourtNoEdit)} />

        <Route path='/pay-bill' component={requireAuth(Paybill)} />
        <Route path='/pay-bill-form/:id' component={requireAuth(PayBillForm)} />
        <Route path='/payment-history' component={requireAuth(PaymentHistory)} />
        <Route path='/payment-request' component={requireAuth(PaymentRequest)} />

        <Route path='/subscription' component={requireAuth(Subscription)} />
        <Route path='/subscription-add' component={requireAuth(SubscriptionAdd)} />
        <Route path='/subscription-edit/:id' component={requireAuth(SubscriptionEdit)} />
        <Route path='/promocode-apply' component={requireAuth(PromoApply)} />

        <Route path='/promocode' component={requireAuth(Promocode)} />
        <Route path='/promocode-add' component={requireAuth(AddPromocode)} />
        <Route path='/promocode-edit/:id' component={requireAuth(EditPromocode)} />
        <Route path='/promocode-apply' component={requireAuth(PromocodeApply)} />

        <Route path='/role' component={requireAuth(Role)} />
        <Route path='/account-setting' component={requireAuth(Profile)} />
        <Route path='/edit-profile' component={requireAuth(EditProfile)} />
        <Route path='/service-config' component={requireAuth(ServiceConfig)} />
        <Route path='/billing-address' component={requireAuth(BillingAddress)} />
        <Route path='/legal-expert-category' component={requireAuth(LegalExpertCategory)} />
        <Route path='/change-password' component={requireAuth(ChangePassword)} />

    </Switch>
</main>
);
export { MainRoute };