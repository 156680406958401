import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Link from '@material-ui/core/Link';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));




 const AccountSettingList = () => (
  
  <div>
    
    <ListItem>
      <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
      <Link href='#/account-setting'>Account Setting</Link>
     
    </ListItem>
    
    <ListItem>
    <ListItemIcon></ListItemIcon>
      <Link href='#/service-config'>Service Configuration</Link><br/>
      </ListItem>
      <ListItem><ListItemIcon></ListItemIcon>
      <Link href='#/billing-address'>Billing Address</Link><br />
      </ListItem>
      <ListItem>
        <ListItemIcon></ListItemIcon>
      <Link href='#/legal-expert-category'>Legal Category Expert</Link>
    </ListItem>
    <ListItem>
        <ListItemIcon></ListItemIcon>
      <Link href='#/change-password'>Change Password</Link>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <Link href="#/dashboard">Back To Dashborad</Link>
    </ListItem>
   </div> 
   
);

export default AccountSettingList;