import _ from 'lodash';
import { SET_CURRENT_USER } from '../actions/auth_actions'
//import { createStore, combineReducers } from 'redux'
const initialState = {
    isAuthenticated: JSON.parse(localStorage.getItem('userData')) ? true : false,
    user: {}
}; 

export default (state = initialState, action) => {
    
    switch (action.type) {   
             
        case SET_CURRENT_USER:
           
           return { ...state, 
                isAuthenticated: !_.isEmpty(action.user),
                user: action.user
            };

        default:
            return state;
     }
};

