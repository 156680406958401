import React, {useState, useEffect} from 'react'
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GavelIcon from '@material-ui/icons/Gavel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { logout } from '../../actions/auth_actions';
import axios from 'axios';
import { BASE } from "../../actions";
import { hasRole } from '../../utils/hasRoleCheck';
import {
  ROLE_ADMIN,
  ROLE_LAWYER,

} from '../common/ConstantValues';
import AppBarMain from '../shared/AppBarMain';
import DrawerMenu from '../shared/DrawerMenu';


function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}{new Date().getFullYear()}
        {'.'}
        <Link color="inherit" href="https://fountainitservice.com">
          Fountain Software & IT Services
        </Link>{' '}
        
      </Typography>
    );
  }

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    //height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function Dashboard(props) {
  console.log('xxxxxxxxxxxxxxxx')
  console.log(props.counter)
  console.log(props)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout=(event) =>{
        
    logout(); 
    
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [post, setPost] = useState({});
  const [top, setTopCase] = useState([]);
  const [islawyerid, setLawyerId ] = useState("");
  const [isSubscription, setSubscription ] = useState("");
  
  const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
  //const id = props.match.params.id;
  const api = axios.create({
    baseURL: BASE
  });
  function saveFcmToken(fcmToken){
    if(localStorage.getItem('userData'))
    {
     
    api.post("/savetoken", {
      user_id: user_id,
      fcm_token: fcmToken,
      is_web:1
    }).then(function (response) {
      console.log(response);
    })
  }
    //console.log(e.target.value);
}
  useEffect(() => {

    if(localStorage.getItem('fcm_token'))
    {
      saveFcmToken(localStorage.getItem('fcm_token'))
    }

    if(hasRole([ROLE_ADMIN])){
    api.get('/casescount/'+user_id)
    .then(res => {
      const cases = res.data;
      setPost( cases );  
    })}else{
      api.get('/casescount/'+user_id)
      .then(res => {
        const cases = res.data;
        setPost( cases );  
      });
      api.get('/users-lawyer/'+user_id)
      .then(res => {
        const lawyer_id='';
        const lawyerd = res.data;
        if(lawyerd){
          setLawyerId( lawyerd );  
       }
         else{
          
          setLawyerId( lawyerd ); 
         }
        
      });
      api.get('/checksubcription/'+user_id)
      .then(res => {
        const subsc = res.data;
        setSubscription( subsc );  
      });
      
    }
    api.get('/dashboard/cases/'+user_id)
          .then(res => {
            const cases1 = res.data;
            setTopCase( cases1 );
          })
  }, []);

  
  return (
    <div className={classes.root}>
          <CssBaseline />
          <AppBarMain counter={props.counter} pageName="Dashboard" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
          <DrawerMenu />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <p>{(islawyerid) && <div> Please Add LAWYER ID!!<a href="#/edit-profile">Click Here</a></div>}</p>
          
          <p className="btn btn-warning btn-sm">{isSubscription.info} {isSubscription.sub_msg} {isSubscription.expired}</p>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={4} lg={4}>
            <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          TOTAL CASE 
        </Typography>
        <Typography variant="body2" component="p">
          Total Active & Close Cases.
          <br />
         
        </Typography>
        <Typography variant="h5" component="h2">
        <ListItemIcon>
        <GavelIcon />
      </ListItemIcon> {post.allcount}
        </Typography>
      </CardContent>
    
    </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
            <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          ACTIVE CASE 
        </Typography>
        <Typography variant="body2" component="p">
          Total Active Cases.
          <br />
         
        </Typography>
        <Typography variant="h5" component="h2">
         {post.activecount}
        </Typography>
      </CardContent>
    
    </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
            <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          CLOSE CASE 
        </Typography>
        <Typography variant="body2" component="p">
          Total Close Cases.
          <br />
         
        </Typography>
        <Typography variant="h5" component="h2">
        {post.closedcount}
        </Typography>
      </CardContent>
    
    </Card>
            </Grid>
          </Grid>
          <h4 className="py-3">Nearest Cases Dates</h4>
          <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Case Number</StyledTableCell>
            <StyledTableCell align="right">Case Type</StyledTableCell>
            <StyledTableCell align="right">Court</StyledTableCell>
            <StyledTableCell align="right">For</StyledTableCell>
            <StyledTableCell align="right">Next Date</StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {top.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.case_id_no}
              </StyledTableCell>
              <StyledTableCell align="right">{row.case_type}</StyledTableCell>
              <StyledTableCell align="right">{row.court_name}</StyledTableCell>
              <StyledTableCell align="right">{row.case_for}</StyledTableCell>
              <StyledTableCell align="right">{row.next_date}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}