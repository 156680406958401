import React, {useState, useEffect} from 'react'

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import Link from '@material-ui/core/Link';

import Button from '@material-ui/core/Button';
import axios from 'axios';
import { BASE } from "../../actions";
import { Redirect } from 'react-router';
import AppBarMain from '../shared/AppBarMain';
import DrawerMenu from '../shared/DrawerMenu';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}{new Date().getFullYear()}
      {'.'}
      <Link color="inherit" href="https://fountainitservice.com">
        Fountain Software & IT Services
      </Link>{' '}
      
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
root: {
  display: 'flex',
},
toolbar: {
  paddingRight: 24, // keep right padding when drawer closed
},
toolbarIcon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  ...theme.mixins.toolbar,
},
appBar: {
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
},
appBarShift: {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
menuButton: {
  marginRight: 36,
},
menuButtonHidden: {
  display: 'none',
},
title: {
  flexGrow: 1,
},
drawerPaper: {
  position: 'relative',
  whiteSpace: 'nowrap',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
},
drawerPaperClose: {
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: theme.spacing(7),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9),
  },
},
appBarSpacer: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
},
container: {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
},
paper: {
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
},
fixedHeight: {
  height: 240,
},
}));

  
  function  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;
  
      if (!input["name"]) {
        isValid = false;
        errors["name"] = "Please enter your name.";
      }
  
      if (!input["email"]) {
        isValid = false;
        errors["email"] = "Please enter your email Address.";
      }
  
      if (typeof input["email"] !== "undefined") {
          
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["email"] = "Please enter valid email address.";
        }
      }
  
      if (!input["comment"]) {
        isValid = false;
        errors["comment"] = "Please enter your comment.";
      }
  
      this.setState({
        errors: errors
      });
  
      return isValid;
  }
  

export default function EditPromocode(props) {
  const [redirect, setredirect] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [name, setName] = useState("")
  const [lawyerid, setLawyerId ] = useState("");
  const [nameerr, seterrName] = useState({})
  const [lawyeriderr, seterrLawyerID] = useState({})
  const [post, setPost] = useState({});
  const id = props.match.params.id;
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const api = axios.create({
    baseURL: BASE
  });
  useEffect(() => {
    api.get('/promo-codes/'+id)
    .then(res => {
      const subscriptions = res.data;
      setPost( subscriptions );
      setisChecked((subscriptions.is_expire==1)?true:false);
    })
  }, []);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSubmit = (e) => {
      
    e.preventDefault();
    const isValid = formValidation();
   
    if(isValid){
      const data = new FormData(e.target);
     
      const generated_code = data.get('generated_code');
      const start_date = data.get('start_date');
      const expire_date = data.get('expire_date');
      const discount = data.get('discount');
      const is_expire = data.get('is_expire');
      
      const api = axios.create({
        baseURL: BASE
      });
       api.put("/promo-codes/edit/"+id, {
         'generated_code':generated_code,
         'start_date':start_date,
          'expire_date':expire_date,
          'discount':discount,
          'is_expire':is_expire
        })
        .then((res) => {
         alert('success');
         setredirect(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  const formValidation = () =>{
    const nameerr= {};
    const lawyeriderr ={};
    let isValid = true;
    
    
    seterrName(nameerr);
    seterrLawyerID(lawyeriderr);
    return isValid;
  }
  const toggleChange = () => {
    setisChecked(!isChecked)
  }
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  if (redirect) {
    return (<Redirect to={'/promocode'} />); 
  }
    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarMain pageName="Promocode Edit" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
          <DrawerMenu />
        
        <main className={classes.content}>
      <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ height: 400, width: '100%' }}>
            <h4 style={{ background:'#ddd',padding:'10px'}}>Edit Promocode</h4>
            <a href="#/promocode" className="btn btn-primary float-right btn-sm">Back</a>
            <div className="col-md-4 mx-auto">
            <form onSubmit={handleSubmit}  className={classes.form} noValidate>
            <div className="form-group">
                <label for="generated_code">Code<span className="text-danger">*</span></label>
                <input id="generated_code" className="form-control"
                 name="generated_code"
                 onChange={(e)=>{setName(e.target.value)}}
                 defaultValue={post.generated_code}
                 />
                 {Object.keys(nameerr).map((key)=>{
                   return nameerr[key];
                 })}
            </div>
            
            <div className="form-group">
                <label for="start_date">Start Date<span className="text-danger">*</span></label>
                <input id="start_date" type="date"  defaultValue={post.start_date} className="form-control" name="start_date"
                                              
                />
                
            </div>
            <div className="form-group">
                <label for="expire_date">Expire Date</label>
                <input id="expire_date" type="date" defaultValue={post.expire_date} className="form-control" name="expire_date"/>
            </div>
            <div className="form-group">
                <label for="discount">Discount(%)<span className="text-danger">*</span></label>
                <input id="discount" type="number" className="form-control" defaultValue={post.discount} name="discount"/>
            </div>
            <div className="form-group">
                 
                <label><input type="checkbox" onChange={toggleChange} id="is_expire" value='1' checked={isChecked} name="is_expire"/> Is Expire</label>
                
            </div>
            <Button
            type="submit"
            
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update
          </Button>
            </form>
         </div>
         </div>
         </Container>
        
         </main>
        
        </div>
    )
}
