import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
import {LOCAL_STORAGE_DATA_KEYNAME} from "../../actions/types"
export default class GetList extends Component {
    state = {
        persons: []
      }
    
      componentDidMount() {
        const api = axios.create({
            baseURL: BASE
          });
        api.get('/users')
          .then(res => {
            const persons = res.data;
            this.setState({ persons });
          })
      }           
      handleDelete(id,status) {
        if(window.confirm("Are you sure you want to "+status+" this item?")){
          const api = axios.create({
            baseURL: BASE
          });
        api.patch('/users/activate/'+id+'/'+status)
          .then(res => {
            alert('Success');
          })
        }
      }
    render() {
        
        return (
            <div className="table-responsive">
                
                <table className="table table-striped">
                    <thead>
                        <tr>
                          <th>SL No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Action</th>
                        </tr>
                    
                    </thead>
                    <tbody>
                    { this.state.persons.map((person,index) =><tr>
                      <td>{++index}</td>
                      <td>{person.name}</td>
                      <td>{person.email}</td>
                      <td>{person.phone}</td>
                      <td>
                      {person.user_role.map(item => <a> {item.role.name}</a>)}</td>
                      <td>{(person.is_active==1?
                      'Acitve'
                        :
                         'Deactivate')}
                         </td>
                      <td><a className="btn btn-sm btn-primary" href={'#/users/edit/'+person.id}>Edit</a>
                      {(person.is_active==1?
                      <span className="btn btn-danger btn-sm" onClick={(e)=>{
                          e.preventDefault();
                          this.handleDelete(person.id,'deactivate');
                        }}>Deactivate
                        </span>:
                         <span className="btn btn-success btn-sm" onClick={(e)=>{
                          e.preventDefault();
                          this.handleDelete(person.id,'active');
                        }}>Acitve</span>
                        )}
                        </td></tr>)}
                </tbody>
                </table>
           
            </div>
        )
    }
    
}