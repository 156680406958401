import React, { Component } from 'react'
import axios from 'axios';
import { BASE } from "../../actions";
export default class LegalCategory extends Component {
    constructor() {
        super();
        this.state = {
          categories: [],
          seletectedItems: [],
          checkedItems: new Map(),
          checked: false
        };
      
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
         
      handleChange(event) {
            var isChecked = event.target.checked;
            var item = event.target.value;
             
            this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
      }
         
      handleSubmit(event) {
        
        event.preventDefault();
        const data = new FormData(event.target);
        const api = axios.create({
          baseURL: BASE
        });
        api.post("/user-expert-category", data)
          .then((res) => {
            console.log(res);
     
            alert('success');
      
          })
          .catch(res => {
            console.log(res)
           
            alert('sorry Try again!!');
          
            
        })
      
      }
      componentDidMount() {
        const api = axios.create({
            baseURL: BASE
          });

        const user_id = JSON.parse(localStorage.getItem('userData')).user.id;
        api.get(`/user-expert-category/`+user_id)
          .then(res => {
            
            const types1 = res.data.rows;
            this.setState({ seletectedItems:types1 });
          })
          api.get(`/expert-category`)
          .then(res => {
            
            const types = res.data;
            this.setState({ categories:types });
          })
          
         
      } 
    render() {
        return (
            <div>
              
                 <form onSubmit={this.handleSubmit}>
                   <input type ="hidden" name="user_id" value={JSON.parse(localStorage.getItem('userData')).user.id} />
                   
           {
             this.state.categories.map(item => ( 
            
               <li>
                   
                  
                 <label>
                   <input
                     type="checkbox"
                     value={item.id}
              
                     defaultChecked={(this.state.seletectedItems.includes(item.name))?'checked':''}
                     name="categories[]"
                     onChange={this.handleChange}
                   /> {item.name}
                 </label>
               </li>
             ))
           }
            
           <br/>
           <input type="submit" value="Submit" />
         </form>
            </div>
        )
    }
}
