import React, {useState, useEffect} from 'react'
import Navlist from '../dashboard/Navlist';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { BASE } from "../../actions";
import { hasRole } from "../../utils/hasRoleCheck";
import ProfileMenu from "../common/ProfileMenu";
import DropdownList from 'react-widgets/lib/DropdownList';
import AppBarMain from '../shared/AppBarMain';
import DrawerMenu from '../shared/DrawerMenu';
import  { Redirect } from 'react-router-dom'
import { useHistory } from "react-router-dom";
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}{new Date().getFullYear()}
        {'.'}
        <Link color="inherit" href="https://fountainitservice.com">
          Fountain Software & IT Services
        </Link>{' '}
        
      </Typography>
    );
  }
  
  const drawerWidth = 240;
  
  const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  }));
 
export default function AddIssue(props) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const [error,setError] = React.useState('');
    const [subject,setSubject] = React.useState('');
    const [description,setDescription] = React.useState('');
    const [redirect, setredirect] = useState(false);
    const [load, setLoad] = useState(null);
    const handleSubmit = (e) => {
      setLoad(true);
      e.preventDefault();
      const { history } = e;
     
      const data = new FormData(e.target);
      console.log(data)
      const user_id=JSON.parse(localStorage.getItem('userData')).user.id
      console.log("user_id")
      console.log(user_id)
      console.log(subject)
      console.log(description)
      const api = axios.create({
        baseURL: BASE
      });
      api.post("/support-tickets", {user_id:user_id,subject:subject,description:description})
        .then((res) => {
          console.log("res");
          console.log(res);
          setLoad(false);
          setredirect(true);
          alert('success');
          return <Redirect to='/issues' />
         
        })
        .catch(res => {
          console.log(res)
          setLoad(false); 
          alert('sorry Try again!!');
          setError(res.response.data.name);
          //setEmailError(res.response.data.email);
          //setPasswordError(res.response.data.password);
          //setRePasswordError(res.response.data.confirm_password);
          
      })
    };
  const handleSubjectChange = (e) => { 
      setSubject(e.target.value)  
}
const handleDescriptionChange = (e) => { 
  setDescription(e.target.value)  
}

    const handleDrawerClose = () => {
      setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  
 // const id = props.match.params.id;
 
  const api = axios.create({
    baseURL: BASE
  });

  // useEffect(() => {
   
  //   api.get('/case-type')
  //   .then(res => {
  //     const types = res.data;
  //     setType( types );
  
  //   })
  //   api.get('/case-for')
  //   .then(res => {
  //     const casefors = res.data;
  //     setCase( casefors );
  //    })
  //   api.get('/courtno')
  //   .then(res => {
  //     const courtfors = res.data;
  //     setCourtno( courtfors );
     
  //   })
  //   api.get('/area')
  //   .then(res => {
  //     const areas = res.data;
  //     setAreas( areas );
     
  //   })
  // }, []);
  

   // let colors = post;
   if (redirect) {
    return (<Redirect to={'/issues'} />); 
  }
      return (
          <div className={classes.root}>
          <CssBaseline />
          <AppBarMain counter={props.counter} pageName="Add Case" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}/>
          <DrawerMenu />
         <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <div style={{ height: 400, width: '100%' }}>
              <h4 style={{ background:'#ddd',padding:'10px'}}>Add New Case</h4>
              <p>Please Enter Issue Details</p>
              <form onSubmit={handleSubmit} className={classes.form} noValidate style={{ width:'400px',margin:'0 auto'}}>
              
    {(hasRole(['LAWYER']))&&
    <div>
    <input type="hidden" name="user_id" value={JSON.parse(localStorage.getItem('userData')).user.id} />
    </div>
}
      <div className="form-group">
              <label for="subject">Subject</label>
         <input type="text" 
         className="form-control"
          name="subject"
          id="subject"
          placeholder="Enter Subject"
          onChange={handleSubjectChange} />
        </div>
        <div className="form-group">
              <label for="description">Description<span className="text-danger">*</span></label>
              <textarea 
              id="description"
               className="form-control"
                name="description"
                placeholder="Enter Description"
                onChange={handleDescriptionChange}
                rows={5}
                 />
        </div>
        
          <Button
            type="submit"
            style={{ margin: 8 }}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
          </form>
           </div>
           </Container>
          
           </main>
          
          </div>
      )
}
